"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageTransform = exports.ImageEditorView = exports.PanelNames = void 0;
var PanelNames;
(function (PanelNames) {
    PanelNames["Files"] = "files";
    PanelNames["Scale"] = "scale";
    PanelNames["Text"] = "text";
    PanelNames["Layout"] = "layout";
    PanelNames["Signature"] = "signature";
})(PanelNames = exports.PanelNames || (exports.PanelNames = {}));
var ImageEditorView;
(function (ImageEditorView) {
    ImageEditorView["Product"] = "product";
    ImageEditorView["PrintArea"] = "printarea";
})(ImageEditorView = exports.ImageEditorView || (exports.ImageEditorView = {}));
var ImageTransform;
(function (ImageTransform) {
    ImageTransform["RotateEnabled"] = "rotateEnabled";
    ImageTransform["ResizeEnabled"] = "resizeEnabled";
    ImageTransform["Crop"] = "crop";
})(ImageTransform = exports.ImageTransform || (exports.ImageTransform = {}));
