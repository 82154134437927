"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Info = void 0;
var React = __importStar(require("react"));
exports.Info = function (props) {
    var _a, _b, _c;
    var page = window.__initialState.page;
    return (React.createElement("div", { className: "custom-accordion" },
        React.createElement("div", { className: "accord" },
            React.createElement("input", { type: "checkbox", id: "rd1", defaultChecked: true, name: "rd" }),
            React.createElement("label", { className: "accord-label", htmlFor: "rd1" },
                React.createElement("span", { className: "text" }, "PRODUCT DETAILS"),
                " ",
                React.createElement("span", { className: "icon" },
                    React.createElement("i", { className: "fas fa-angle-right" }))),
            React.createElement("div", { className: "accord-content", dangerouslySetInnerHTML: { __html: (_a = page === null || page === void 0 ? void 0 : page.category) === null || _a === void 0 ? void 0 : _a.description } })),
        React.createElement("div", { className: "accord" },
            React.createElement("input", { type: "checkbox", id: "rd2", name: "rd" }),
            React.createElement("label", { className: "accord-label", htmlFor: "rd2" },
                React.createElement("span", { className: "text" }, "SPECIFICATION"),
                " ",
                React.createElement("span", { className: "icon" },
                    React.createElement("i", { className: "fas fa-angle-right" }))),
            React.createElement("div", { className: "accord-content", dangerouslySetInnerHTML: { __html: (_b = page === null || page === void 0 ? void 0 : page.category) === null || _b === void 0 ? void 0 : _b.description2 } })),
        React.createElement("div", { className: "accord" },
            React.createElement("input", { type: "checkbox", id: "rd3", name: "rd" }),
            React.createElement("label", { className: "accord-label", htmlFor: "rd3" },
                React.createElement("span", { className: "text" }, "SHIPPING"),
                " ",
                React.createElement("span", { className: "icon" },
                    React.createElement("i", { className: "fas fa-angle-right" }))),
            React.createElement("div", { className: "accord-content", dangerouslySetInnerHTML: { __html: (_c = page === null || page === void 0 ? void 0 : page.category) === null || _c === void 0 ? void 0 : _c.description3 } }))));
};
