"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paginator = void 0;
var React = __importStar(require("react"));
var paginator = __importStar(require("pagination"));
exports.Paginator = function (props) {
    var _a, _b;
    var className = props.className, data = props.data, onPageUpdate = props.onPageUpdate;
    if (!data || !data.total) {
        return null;
    }
    var current = parseInt(data.skip) / parseInt(data.limit) + 1;
    var paginationConfig = {
        totalResult: data.total,
        current: current,
        rowsPerPage: data.limit
    };
    var p = new paginator.SearchPaginator(paginationConfig);
    var paginationData = p.getPaginationData();
    if (((_a = paginationData === null || paginationData === void 0 ? void 0 : paginationData.range) === null || _a === void 0 ? void 0 : _a.length) === 0) {
        paginationData.range.push(1);
    }
    var pages = (_b = paginationData === null || paginationData === void 0 ? void 0 : paginationData.range) === null || _b === void 0 ? void 0 : _b.map(function (page, index) {
        return (React.createElement("li", { key: page, className: "page-item " + (paginationData.current === page ? "active" : "") },
            React.createElement("button", { className: "page-link", type: "button", onClick: function () { return onPageUpdate((page - 1) * (data === null || data === void 0 ? void 0 : data.limit)); } }, page)));
    });
    if (paginationData.range.length === 0) {
        paginationData.range.push(1);
    }
    if (paginationData.previous) {
        pages.unshift(React.createElement("li", { className: "page-item", key: "caret-left" },
            React.createElement("button", { className: "page-link", type: "button", onClick: function () {
                    return onPageUpdate((paginationData.previous - 1) * (data === null || data === void 0 ? void 0 : data.limit));
                } },
                React.createElement("i", { className: "fas fa-caret-left" }))));
    }
    else {
        pages.unshift(React.createElement("li", { className: "page-item disabled", key: "caret-left" },
            React.createElement("button", { className: "page-link", type: "button", disabled: true },
                React.createElement("i", { className: "fas fa-caret-left" }))));
    }
    if (paginationData.first) {
        pages.unshift(React.createElement("li", { className: "page-item", key: "first" },
            React.createElement("button", { className: "page-link", type: "button", onClick: function () { return onPageUpdate(0); } }, "First")));
    }
    else {
        pages.unshift(React.createElement("li", { className: "page-item disabled", key: "first" },
            React.createElement("button", { className: "page-link", type: "button", disabled: true }, "First")));
    }
    if (paginationData.next) {
        pages.push(React.createElement("li", { className: "page-item", key: "caret-right" },
            React.createElement("button", { className: "page-link", type: "button", onClick: function () { return onPageUpdate((paginationData.next - 1) * (data === null || data === void 0 ? void 0 : data.limit)); } },
                React.createElement("i", { className: "fas fa-caret-right" }))));
    }
    else {
        pages.push(React.createElement("li", { className: "page-item disabled", key: "caret-right" },
            React.createElement("button", { className: "page-link", type: "button", disabled: true },
                React.createElement("i", { className: "fas fa-caret-right" }))));
    }
    if (paginationData.last) {
        pages.push(React.createElement("li", { className: "page-item", key: "last" },
            React.createElement("button", { className: "page-link", type: "button", onClick: function () { return onPageUpdate((paginationData.last - 1) * (data === null || data === void 0 ? void 0 : data.limit)); } }, "Last")));
    }
    else {
        pages.push(React.createElement("li", { className: "page-item disabled", key: "last" },
            React.createElement("button", { className: "page-link", type: "button", disabled: true }, "Last")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { "aria-label": "Page navigation example" },
            React.createElement("ul", { className: "pagination pagination-sm" }, pages))));
};
