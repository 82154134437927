"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
var react_1 = __importStar(require("react"));
var react_intl_1 = require("react-intl");
exports.Table = function (props) {
    var _a, _b;
    var items = props.items;
    var _c = react_1.useState({}), checkItems = _c[0], setCheckItems = _c[1];
    var handleCheck = function (id) { return function (e) {
        var _a;
        var checked = e.target.checked;
        setCheckItems(__assign(__assign({}, checkItems), (_a = {}, _a[id] = checked, _a)));
    }; };
    var handleToggleCheckAll = function (e) {
        var newItems = items === null || items === void 0 ? void 0 : items.reduce(function (acc, cur) {
            acc[cur._id] = e.target.checked;
            return acc;
        }, {});
        setCheckItems(newItems);
    };
    var checkAllItems = (_a = Object.keys(checkItems)) === null || _a === void 0 ? void 0 : _a.every(function (item) { return (checkItems === null || checkItems === void 0 ? void 0 : checkItems[item]) === true; });
    var isAllChecked = (items === null || items === void 0 ? void 0 : items.length) === ((_b = Object === null || Object === void 0 ? void 0 : Object.keys(checkItems)) === null || _b === void 0 ? void 0 : _b.length) && checkAllItems;
    var mappedItems = items === null || items === void 0 ? void 0 : items.map(function (item) {
        var _a, _b;
        if ((item === null || item === void 0 ? void 0 : item.metadata) && ((_a = item.metadata) === null || _a === void 0 ? void 0 : _a.length)) {
            item.metadata = (_b = item.metadata) === null || _b === void 0 ? void 0 : _b.map(function (m) {
                if (m.key !== "printribe_status") {
                    return m;
                }
                if (m.value !== "void") {
                    return m;
                }
                m.value = "cancelled";
                return m;
            });
        }
        return item;
    });
    return (react_1.default.createElement("table", { className: "table table-borderless ic-dash-order-table" },
        react_1.default.createElement("thead", null,
            react_1.default.createElement("tr", { className: "text-center" },
                react_1.default.createElement("th", { className: "text-left" },
                    react_1.default.createElement("input", { type: "checkbox", checked: isAllChecked, onChange: handleToggleCheckAll })),
                react_1.default.createElement("th", { className: "text-left" }, "Order"),
                react_1.default.createElement("th", null, "Order Date"),
                react_1.default.createElement("th", null, "Items"),
                react_1.default.createElement("th", null, "Names"),
                react_1.default.createElement("th", null, "Destination"),
                react_1.default.createElement("th", null, "Payment"),
                react_1.default.createElement("th", null, "Status"))),
        react_1.default.createElement("tbody", null, items === null || items === void 0 ? void 0 : items.map(function (i, index) {
            var _a, _b, _c, _d, _e, _f, _g;
            return (react_1.default.createElement("tr", { key: index, className: "text-center" },
                react_1.default.createElement("td", { className: "text-left" },
                    react_1.default.createElement("input", { type: "checkbox", onChange: handleCheck(i._id), checked: checkItems === null || checkItems === void 0 ? void 0 : checkItems[i._id] })),
                react_1.default.createElement("td", { className: "text-left" },
                    react_1.default.createElement("a", { href: "/dashboard/order-details?orderId=" + (i === null || i === void 0 ? void 0 : i._id) }, i === null || i === void 0 ? void 0 : i.humanId)),
                react_1.default.createElement("td", null,
                    react_1.default.createElement(react_intl_1.FormattedDate, { value: i.createdAt })),
                react_1.default.createElement("td", null, ((_a = i === null || i === void 0 ? void 0 : i.orderItems) === null || _a === void 0 ? void 0 : _a.length) || 1),
                react_1.default.createElement("td", null, (_b = i === null || i === void 0 ? void 0 : i.shipment) === null || _b === void 0 ? void 0 : _b.shipToName),
                react_1.default.createElement("td", null, (_c = i === null || i === void 0 ? void 0 : i.shipment) === null || _c === void 0 ? void 0 :
                    _c.shipToCity,
                    " ", (_d = i === null || i === void 0 ? void 0 : i.shipment) === null || _d === void 0 ? void 0 :
                    _d.shipToStateProvince,
                    " ", (_e = i === null || i === void 0 ? void 0 : i.shipment) === null || _e === void 0 ? void 0 :
                    _e.shipToCountryCode),
                react_1.default.createElement("td", null,
                    "AUD $",
                    (i === null || i === void 0 ? void 0 : i.invoiceTotal) || "0.00"),
                react_1.default.createElement("td", null,
                    react_1.default.createElement("span", { className: "badge badge-pill badge-light" },
                        react_1.default.createElement("span", { className: "ic-status " + ((i === null || i === void 0 ? void 0 : i.productionStatus) === "complete" ? "green" : "") }, (_g = (_f = i === null || i === void 0 ? void 0 : i.metadata) === null || _f === void 0 ? void 0 : _f.find(function (k) { return k.key === "printribe_status"; })) === null || _g === void 0 ? void 0 : _g.value)))));
        }))));
};
