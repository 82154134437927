"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var remote_1 = require("./../../remote");
var common_1 = require("./../common");
var useContext = React.useContext, useState = React.useState;
var baseUrl = "https://storage.googleapis.com/icstorage/";
exports.default = (function (props) {
    var onClose = props.onClose, onSave = props.onSave;
    var context = useContext(Context_1.ProductContext);
    var selectedProduct = context.selectedProduct, saveArtworkDetail = context.saveArtworkDetail, setSaveArtworkDetail = context.setSaveArtworkDetail, productName = context.productName, 
    // saveArtwork,
    // saveArtworkThumb,
    // selectedCollection,
    // selectedArtwork,
    setProductName = context.setProductName, refHandleExport = context.refHandleExport;
    var _a = useState(false), save = _a[0], setSave = _a[1];
    var _b = useState({
        save: false
    }), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var _c = useState(), productSku = _c[0], setProductSku = _c[1];
    var _d = useState(""), responseMsg = _d[0], setResponseMsg = _d[1];
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var artworkUploadImage, artworkThumbUploadImage, artworkBlob, artworkThumbBlob, resArtworkUploadImage, resArtworkThumbUploadImage, artwork, thumb, res, e_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        return __generator(this, function (_w) {
            switch (_w.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { save: true }));
                    _w.label = 1;
                case 1:
                    _w.trys.push([1, 8, , 9]);
                    artworkUploadImage = saveArtworkDetail === null || saveArtworkDetail === void 0 ? void 0 : saveArtworkDetail.artwork, artworkThumbUploadImage = saveArtworkDetail === null || saveArtworkDetail === void 0 ? void 0 : saveArtworkDetail.thumb;
                    console.log({ saveArtworkDetail: saveArtworkDetail });
                    if (!!(saveArtworkDetail === null || saveArtworkDetail === void 0 ? void 0 : saveArtworkDetail.artwork)) return [3 /*break*/, 6];
                    return [4 /*yield*/, ((_a = refHandleExport === null || refHandleExport === void 0 ? void 0 : refHandleExport.current) === null || _a === void 0 ? void 0 : _a.export())];
                case 2:
                    artworkBlob = _w.sent();
                    return [4 /*yield*/, ((_b = refHandleExport === null || refHandleExport === void 0 ? void 0 : refHandleExport.current) === null || _b === void 0 ? void 0 : _b.export("thumb"))];
                case 3:
                    artworkThumbBlob = _w.sent();
                    return [4 /*yield*/, remote_1.uploadImage(artworkBlob)];
                case 4:
                    resArtworkUploadImage = _w.sent();
                    return [4 /*yield*/, remote_1.uploadImage(artworkThumbBlob)];
                case 5:
                    resArtworkThumbUploadImage = _w.sent();
                    artwork = (_g = (_f = (_e = (_d = (_c = resArtworkUploadImage === null || resArtworkUploadImage === void 0 ? void 0 : resArtworkUploadImage.data) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.files) === null || _e === void 0 ? void 0 : _e.files) === null || _f === void 0 ? void 0 : _f[0]) !== null && _g !== void 0 ? _g : (_k = (_j = (_h = resArtworkUploadImage === null || resArtworkUploadImage === void 0 ? void 0 : resArtworkUploadImage.data) === null || _h === void 0 ? void 0 : _h.files) === null || _j === void 0 ? void 0 : _j.files) === null || _k === void 0 ? void 0 : _k[0];
                    thumb = (_p = (_o = (_m = (_l = resArtworkThumbUploadImage === null || resArtworkThumbUploadImage === void 0 ? void 0 : resArtworkThumbUploadImage.data) === null || _l === void 0 ? void 0 : _l.files) === null || _m === void 0 ? void 0 : _m.files) === null || _o === void 0 ? void 0 : _o[0]) !== null && _p !== void 0 ? _p : (_t = (_s = (_r = (_q = resArtworkThumbUploadImage === null || resArtworkThumbUploadImage === void 0 ? void 0 : resArtworkThumbUploadImage.data) === null || _q === void 0 ? void 0 : _q.response) === null || _r === void 0 ? void 0 : _r.files) === null || _s === void 0 ? void 0 : _s.files) === null || _t === void 0 ? void 0 : _t[0];
                    artworkUploadImage = artwork;
                    artworkThumbUploadImage = thumb;
                    _w.label = 6;
                case 6:
                    setSaveArtworkDetail({
                        artwork: artworkUploadImage,
                        thumb: artworkThumbUploadImage
                    });
                    return [4 /*yield*/, remote_1.addProductLibrary({
                            product: selectedProduct,
                            // customerArtwork: resArtworkLib?.data?._id,
                            artwork: artworkUploadImage === null || artworkUploadImage === void 0 ? void 0 : artworkUploadImage.uploadLog,
                            name: productName,
                            customerSku: productSku,
                            thumbnail: {
                                uploadLog: artworkThumbUploadImage === null || artworkThumbUploadImage === void 0 ? void 0 : artworkThumbUploadImage.uploadLog,
                                url: (_v = (_u = artworkThumbUploadImage === null || artworkThumbUploadImage === void 0 ? void 0 : artworkThumbUploadImage.thumbnails) === null || _u === void 0 ? void 0 : _u[0]) === null || _v === void 0 ? void 0 : _v.url
                            }
                        })];
                case 7:
                    res = _w.sent();
                    // setResponseMsg('Product successfully saved!')
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { save: false }));
                    setSave(true);
                    return [2 /*return*/, onSave(true)];
                case 8:
                    e_1 = _w.sent();
                    console.log("e", e_1);
                    return [3 /*break*/, 9];
                case 9:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { save: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var isDisabledSaveBtn = !(productName === null || productName === void 0 ? void 0 : productName.trim());
    return (React.createElement(common_1.ModalWrapper, { isOpen: true, onClose: onClose, style: { maxWidth: "450px" } },
        React.createElement("h3", { className: "text-center" }, "Save Product"),
        React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
            React.createElement("div", { className: "form-group" },
                React.createElement("input", { type: "text", className: "form-control custom-form-control", placeholder: "Enter product name here...", onChange: function (e) { return setProductName(e.target.value); } })),
            React.createElement("div", { className: "form-group" },
                React.createElement("input", { type: "text", className: "form-control custom-form-control", placeholder: "Enter product sku here...", onChange: function (e) { return setProductSku(e.target.value || undefined); } }))),
        isSubmitting.save ? (React.createElement("div", { className: "d-flex justify-content-center" },
            React.createElement("div", { className: "spinner-border txc-pink", role: "status" },
                React.createElement("span", { className: "sr-only" }, "Loading...")))) : (React.createElement("div", { className: "text-center" },
            React.createElement(common_1.Button, { variant: "pink", className: "mx-2", disabled: isSubmitting.save || isDisabledSaveBtn || save, onClick: handleSave, content: "Save" }),
            React.createElement(common_1.Button, { variant: "primary", onClick: onClose, content: "Close" })))));
});
