"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorList = void 0;
var React = __importStar(require("react"));
var react_colorful_1 = require("react-colorful");
var Color_1 = require("./Color");
var helpers_1 = require("./helpers");
require("reactjs-popup/dist/index.css");
var common_1 = require("./../../../components/common");
var useState = React.useState;
function ColorList(props) {
    var colors = props.colors, selected = props.selected, size = props.size, _a = props.columns, columns = _a === void 0 ? 2 : _a, onChange = props.onChange, onChangePalette = props.onChangePalette;
    var batchedItems = helpers_1.batchItems(columns, colors);
    var _b = useState(false), isOpenColorPicker = _b[0], setIsOpenColorPicker = _b[1];
    var handleSelect = function (color) {
        var newColors = __spreadArrays(colors);
        if ((newColors === null || newColors === void 0 ? void 0 : newColors.length) <= 11) {
            newColors.push({ color: color, new: true });
            onChangePalette === null || onChangePalette === void 0 ? void 0 : onChangePalette(newColors);
        }
        else {
            newColors.splice(4, 1);
            onChangePalette === null || onChangePalette === void 0 ? void 0 : onChangePalette(__spreadArrays(newColors, [{ color: color, new: true }]));
        }
    };
    return (React.createElement("div", { className: "d-flex" },
        batchedItems.map(function (b, idx) { return (React.createElement(React.Fragment, { key: idx }, b.map(function (c, idx2) { return (React.createElement("div", { key: idx2 },
            React.createElement(Color_1.Color, { key: idx2, value: c.color, size: size, isSelected: c.color === selected, onClick: onChange }))); }))); }),
        React.createElement("div", null,
            React.createElement("span", { className: "d-inline-block ml-1 rainbow", onClick: function () { return setIsOpenColorPicker(true); } })),
        isOpenColorPicker && (React.createElement(common_1.ModalWrapper, { isOpen: isOpenColorPicker, onClose: function () {
                setIsOpenColorPicker(false);
                onChange("#000000");
            }, style: { maxWidth: "310px" } },
            React.createElement("div", { className: "rounded-corner p-3 bg-white" },
                React.createElement("div", { className: "p-3" },
                    React.createElement(react_colorful_1.HexColorPicker, { color: selected, onChange: onChange })),
                React.createElement("div", { className: "form-group" },
                    React.createElement("input", { type: "text", placeholder: "Hex Code...", className: "form-control custom-form-control", value: selected, onChange: function (e) { return onChange(e.target.value); } })),
                React.createElement("button", { className: "btn btn-pink", onClick: function () {
                        handleSelect(selected);
                        setIsOpenColorPicker(false);
                    } }, "Select"))))));
}
exports.ColorList = ColorList;
