"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Context_1 = require("../Context");
var remote_1 = require("../../../remote");
var common_1 = require("../../common");
var selectedArtworkStyle = {
    padding: ".5em 1em",
    border: "2px solid #fff",
    borderRadius: "1.25em"
};
var useState = React.useState, useEffect = React.useEffect, useRef = React.useRef, useContext = React.useContext;
exports.default = (function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = props.isDisabled, isDisabled = _g === void 0 ? false : _g, onChange = props.onChange;
    var unmounted = useRef(false);
    var context = useContext(Context_1.ProductContext);
    var selectedArtwork = context.selectedArtwork, setImageEditorCtx = context.setImageEditorCtx, setSelectedArtwork = context.setSelectedArtwork, handleSelectArtwork = context.handleSelectArtwork, selectedCollection = context.selectedCollection, setSelectedCollection = context.setSelectedCollection;
    var _h = useState({
        upload: false,
        library: false
    }), isOpenModal = _h[0], setIsOpenModal = _h[1];
    var _j = useState([]), items = _j[0], setItems = _j[1];
    var _k = useState([{ value: "", label: "All Artworks" }]), collections = _k[0], setCollections = _k[1];
    var _l = useState("existing"), typeCollection = _l[0], setTypeCollection = _l[1];
    var _m = useState({}), tempSelectedArtwork = _m[0], setTempSelectedArtwork = _m[1];
    var _o = useState({
        limit: 25,
        skip: 0,
        total: 0
    }), paginationInfo = _o[0], setPaginationInfo = _o[1];
    var _p = useState(0), skip = _p[0], setSkip = _p[1];
    var _q = useState({
        createArtwork: false,
        uploadUrl: false
    }), isSubmitting = _q[0], setIsSubmitting = _q[1];
    var _r = useState({
        createArtwork: "",
        uploadUrl: ""
    }), errorMessage = _r[0], setErrorMessage = _r[1];
    var _s = useState(""), uploadUrl = _s[0], setUploadUrl = _s[1];
    var loadArtwork = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, updatedItems, e_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, remote_1.getArtworkLibrary({ skip: skip })];
                case 1:
                    res = _f.sent();
                    updatedItems = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(function (d) {
                        var _a, _b, _c, _d;
                        return (__assign(__assign({}, d), { itemImgUrl: (_b = (_a = d === null || d === void 0 ? void 0 : d.artworkThumbnails) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url, itemName: (_c = d === null || d === void 0 ? void 0 : d.artwork) === null || _c === void 0 ? void 0 : _c.originalFilename, itemFileExt: (_d = d === null || d === void 0 ? void 0 : d.artwork) === null || _d === void 0 ? void 0 : _d.originalFilenameExtension }));
                    });
                    setPaginationInfo({
                        limit: (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.limit,
                        total: (_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.total,
                        skip: (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.skip
                    });
                    setItems(updatedItems);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _f.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var loadCollection = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, noCollections, e_2;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, remote_1.getArtworkLibraryCollection()];
                    case 1:
                        res = _d.sent();
                        setCollections(__spreadArrays((((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.map(function (d) { var _a; return ({ value: d._id, label: (_a = d === null || d === void 0 ? void 0 : d.name) !== null && _a !== void 0 ? _a : "" }); })) ||
                            []), [
                            { value: "", label: "All Artworks" }
                        ]));
                        noCollections = ((_c = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) < 1;
                        setTypeCollection(noCollections ? "new" : "existing");
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _d.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadCollection();
        return function () {
            unmounted.current = true;
        };
    }, []);
    useEffect(function () {
        loadArtwork();
    }, [skip]);
    // const handleSubmit = async (collectionId?: string) => {
    //   try {
    //     const collections =
    //       typeCollection === "existing"
    //         ? selectedCollection?.map(s => s.value)?.filter(Boolean)
    //         : [collectionId];
    //     const res: any = await createArtworkLibrary(
    //       tempSelectedArtwork?.uploadLog,
    //       collections
    //     );
    //     setSelectedArtwork({
    //       artwork: { _id: res?.data?.artwork, ...tempSelectedArtwork },
    //       _id: res?.data?._id
    //     });
    //     setIsOpenModal({ library: false, upload: false });
    //   } catch (e) {}
    //   // setIsSubmitting({
    //   //   ...isSubmitting,
    //   //   upload: false
    //   // })
    // };
    var handleCreateArtwork = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, resFiles, e_3, errMsg;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { createArtwork: true }));
                    setErrorMessage(__assign(__assign({}, errorMessage), { createArtwork: "" }));
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 5, , 6]);
                    res = void 0;
                    if (!(typeCollection === "new")) return [3 /*break*/, 3];
                    return [4 /*yield*/, remote_1.createArtworkLibraryCollection(selectedCollection)];
                case 2:
                    res = _f.sent();
                    // setIsReload(prev => ({ ...prev, collections: true }))
                    setSelectedCollection([(_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a._id]);
                    _f.label = 3;
                case 3: return [4 /*yield*/, remote_1.createArtworkLibrary(tempSelectedArtwork === null || tempSelectedArtwork === void 0 ? void 0 : tempSelectedArtwork.uploadLog, typeCollection === "new"
                        ? [(_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b._id]
                        : (_c = selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.map(function (s) { return s.value; })) === null || _c === void 0 ? void 0 : _c.filter(Boolean))];
                case 4:
                    resFiles = _f.sent();
                    setSelectedArtwork({
                        artwork: __assign({ _id: (_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.artwork }, tempSelectedArtwork),
                        _id: (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e._id
                    });
                    setIsOpenModal({ library: false, upload: false });
                    // handleSubmit(res?.data?._id);
                    setSelectedCollection(null);
                    return [3 /*break*/, 6];
                case 5:
                    e_3 = _f.sent();
                    errMsg = (e_3 === null || e_3 === void 0 ? void 0 : e_3.code) < 500
                        ? e_3.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(__assign(__assign({}, errorMessage), { createArtwork: errMsg }));
                    return [3 /*break*/, 6];
                case 6:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { createArtwork: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUploadByUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resUpload, res, resArt, e_4, errMsg;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return __generator(this, function (_p) {
            switch (_p.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { uploadUrl: true }));
                    setErrorMessage(__assign(__assign({}, errorMessage), { uploadUrl: "" }));
                    _p.label = 1;
                case 1:
                    _p.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, remote_1.uploadImageByUrl(uploadUrl)];
                case 2:
                    resUpload = _p.sent();
                    res = void 0;
                    if (!(typeCollection === "new")) return [3 /*break*/, 4];
                    return [4 /*yield*/, remote_1.createArtworkLibraryCollection(selectedCollection)];
                case 3:
                    res = _p.sent();
                    _p.label = 4;
                case 4: return [4 /*yield*/, remote_1.createArtworkLibrary((_d = (_c = (_b = (_a = resUpload.data) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.files) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.uploadLog, typeCollection === "new"
                        ? [(_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e._id]
                        : (_f = selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.map(function (s) { return s.value; })) === null || _f === void 0 ? void 0 : _f.filter(Boolean))];
                case 5:
                    resArt = _p.sent();
                    setSelectedArtwork({
                        artwork: ((_j = (_h = (_g = resUpload.data) === null || _g === void 0 ? void 0 : _g.files) === null || _h === void 0 ? void 0 : _h.files) === null || _j === void 0 ? void 0 : _j[0]) || {},
                        _id: (_o = (_m = (_l = (_k = resUpload.data) === null || _k === void 0 ? void 0 : _k.files) === null || _l === void 0 ? void 0 : _l.files) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o._id
                    });
                    setUploadUrl("");
                    setIsOpenModal({ library: false, upload: false });
                    return [3 /*break*/, 7];
                case 6:
                    e_4 = _p.sent();
                    errMsg = (e_4 === null || e_4 === void 0 ? void 0 : e_4.code) < 500
                        ? e_4 === null || e_4 === void 0 ? void 0 : e_4.message : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(__assign(__assign({}, errorMessage), { uploadUrl: errMsg }));
                    return [3 /*break*/, 7];
                case 7:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { uploadUrl: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    // const isEmptyCollection =
    //   selectedCollection === null ||
    //   (Array.isArray(selectedCollection)
    //     ? selectedCollection?.length < 1
    //     : !selectedCollection.trim());
    var urlParams = new URLSearchParams(window.location.search);
    var isUploadByUrlEnabled = urlParams.get("isUploadByUrlEnabled");
    // console.log({isUploadByUrlEnabled})
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12 col-lg-6 mb-3 mb-lg-0" },
                React.createElement(common_1.Button, { className: "btn btn-block btn-primary", disabled: isDisabled, onClick: function () {
                        onChange(false);
                        setIsOpenModal(__assign(__assign({}, isOpenModal), { upload: true }));
                        setSelectedArtwork(null);
                        setTempSelectedArtwork({});
                        setSelectedCollection(null);
                    } }, "UPLOAD ARTWORK")),
            React.createElement("div", { className: "col-12 col-lg-6 mb-3 mb-lg-0" },
                React.createElement(common_1.Button, { disabled: isDisabled, variant: "pink", className: "btn-block", onClick: function () {
                        onChange(false);
                        setIsOpenModal(__assign(__assign({}, isOpenModal), { library: true }));
                        setSelectedArtwork(null);
                        setTempSelectedArtwork({});
                        setSelectedCollection(null);
                    } }, "MY LIBRARY"))),
        selectedArtwork && (React.createElement("div", { className: "ic-selected-artwork mt-3", style: selectedArtworkStyle },
            " ",
            React.createElement("i", { className: "fas fa-check-circle txc-pink" }),
            " ", (_b = (_a = selectedArtwork === null || selectedArtwork === void 0 ? void 0 : selectedArtwork.artwork) === null || _a === void 0 ? void 0 : _a.originalFilename) === null || _b === void 0 ? void 0 :
            _b.replace("." + ((_d = (_c = selectedArtwork === null || selectedArtwork === void 0 ? void 0 : selectedArtwork.artwork) === null || _c === void 0 ? void 0 : _c.originalFilenameExtension) !== null && _d !== void 0 ? _d : ""), ""))),
        (isOpenModal.library || isOpenModal.upload) && (React.createElement(common_1.ModalWrapper, { isOpen: isOpenModal.library || isOpenModal.upload, onClose: function () { return setIsOpenModal({ library: false, upload: false }); }, style: { maxWidth: (isOpenModal === null || isOpenModal === void 0 ? void 0 : isOpenModal.library) ? "900px" : "650px" } },
            isOpenModal.library && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "text-center" }, "My Library"),
                React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                    React.createElement(common_1.Grid, { items: items, type: "radio", onSelect: handleSelectArtwork, style: { maxHeight: "none" } }),
                    React.createElement("div", { className: "d-flex justify-content-center mt-3" },
                        React.createElement(common_1.Paginator, { data: paginationInfo, onPageUpdate: function (v) { return setSkip(v); } }))))),
            isOpenModal.upload && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "text-center" }, "Upload Artwork"),
                React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "form-group form-check" },
                                React.createElement("label", { className: "form-check-label" },
                                    React.createElement("input", { type: "radio", name: "type", className: "form-check-input", disabled: (collections === null || collections === void 0 ? void 0 : collections.length) < 1, checked: typeCollection === "existing", onClick: function (e) {
                                            setTypeCollection("existing");
                                            setSelectedCollection(null);
                                        } }),
                                    "Upload to Existing Collection")),
                            React.createElement(common_1.CustomSelect, { isSearchable: true, isMulti: true, closeMenuOnSelect: false, isDisabled: typeCollection === "new", defaultValue: [{ label: "All Artworks", value: "" }], options: collections, onChange: setSelectedCollection, placeholder: "Choose Collection" })),
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "form-group form-check" },
                                React.createElement("label", { className: "form-check-label" },
                                    React.createElement("input", { type: "radio", name: "type", className: "form-check-input", checked: typeCollection === "new", onClick: function (e) {
                                            setTypeCollection("new");
                                            setSelectedCollection(null);
                                        } }),
                                    "Upload to New Collection")),
                            React.createElement("input", { type: "text", disabled: typeCollection === "existing", className: "form-control custom-form-control", onChange: function (e) {
                                    return setSelectedCollection(e.target.value);
                                } })))),
                React.createElement("div", { className: "rounded-corner p-3 p-md-4 mb-3 bg-white" },
                    React.createElement(common_1.Attachment
                    // isDisable={isEmptyCollection}
                    , { 
                        // isDisable={isEmptyCollection}
                        onUpload: function (uploadedFiles) {
                            if (uploadedFiles === null || uploadedFiles === void 0 ? void 0 : uploadedFiles[0]) {
                                setTempSelectedArtwork(__assign({}, ((uploadedFiles === null || uploadedFiles === void 0 ? void 0 : uploadedFiles[0]) || {})));
                                // setSelectedArtwork({ artwork: uploadedFiles[0] })
                                setImageEditorCtx(null);
                            }
                        } }),
                    (tempSelectedArtwork === null || tempSelectedArtwork === void 0 ? void 0 : tempSelectedArtwork.result) && (React.createElement("div", null,
                        React.createElement("i", { className: "fas mr-2 txc-pink fa-check-circle temp" }),
                        " ", (_e = tempSelectedArtwork === null || tempSelectedArtwork === void 0 ? void 0 : tempSelectedArtwork.originalFilename) === null || _e === void 0 ? void 0 :
                        _e.replace("." + ((_f = ((tempSelectedArtwork === null || tempSelectedArtwork === void 0 ? void 0 : tempSelectedArtwork.originalFilenameExtension) || (tempSelectedArtwork === null || tempSelectedArtwork === void 0 ? void 0 : tempSelectedArtwork.originalExt))) !== null && _f !== void 0 ? _f : ""), ""))),
                    isUploadByUrlEnabled && (React.createElement("div", { className: "input-group my-3" },
                        React.createElement("div", { className: "custom-file" },
                            React.createElement("input", { type: "text", placeholder: "https://", className: "form-control rounded-pill border-dark border-right-radius-0", style: {
                                    borderTopRightRadius: "0px !important",
                                    borderBottomRightRadius: "0px !important"
                                }, value: uploadUrl, onChange: function (e) { return setUploadUrl(e.target.value); } })),
                        React.createElement("div", { className: "input-group-append" },
                            React.createElement(common_1.Button, { variant: "pink", disabled: isSubmitting.uploadUrl, loading: isSubmitting.uploadUrl, content: "Upload", onClick: handleUploadByUrl })))),
                    React.createElement("div", { className: "text-center" },
                        React.createElement("p", { className: "" },
                            React.createElement("strong", null, "PDF, TIFF OR JPG ACCEPTED.")),
                        React.createElement("p", { className: "mb-2" }, "Maximum file size 350 MB"),
                        React.createElement("p", { className: "mb-2" },
                            "For tips on creating a perfectly-sized file, see our FAQs",
                            " ",
                            React.createElement("a", { href: "/faq" }, "here"),
                            "."),
                        React.createElement("p", { className: "mb-3" },
                            React.createElement("em", null,
                                "If you are having issues uploading your file, please contact us at",
                                " ",
                                React.createElement("a", { href: "mailto:info@printribe.com" }, "info@printribe.com")))),
                    errorMessage.createArtwork && (React.createElement("div", { className: "alert alert-danger" }, errorMessage.createArtwork))),
                React.createElement("div", { className: "my-3 text-center" }))),
            React.createElement("div", { style: { position: "sticky", bottom: 0, left: 0 } },
                React.createElement("div", { className: "d-flex justify-content-between" },
                    React.createElement(common_1.Button, { variant: "primary", className: "", onClick: function () {
                            return setIsOpenModal({ library: false, upload: false });
                        } }, "Close"),
                    (tempSelectedArtwork === null || tempSelectedArtwork === void 0 ? void 0 : tempSelectedArtwork.result) && (React.createElement(common_1.Button, { variant: "pink", disabled: isSubmitting.createArtwork, loading: isSubmitting.createArtwork, onClick: handleCreateArtwork }, "Continue"))))))));
});
