"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddArtwork = void 0;
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var remote_1 = require("../../../remote");
var common_1 = require("../../common");
var useContext = React.useContext, useState = React.useState;
exports.AddArtwork = function (props) {
    var _a;
    var ctx = useContext(Context_1.ArtworkContext);
    var files = ctx.files, setFiles = ctx.setFiles, collections = ctx.collections, isModalOpen = ctx.isModalOpen, setIsReload = ctx.setIsReload, selectedCollection = ctx.selectedCollection, setSelectedCollection = ctx.setSelectedCollection, typeCollection = ctx.typeCollection, setTypeCollection = ctx.setTypeCollection, handleToggleModal = ctx.handleToggleModal, handleToggleExclude = ctx.handleToggleExclude, handleSubmit = ctx.handleSubmit;
    var _b = useState({
        createArtwork: false,
        uploadUrl: false
    }), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var _c = useState({
        createArtwork: "",
        uploadUrl: ""
    }), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(""), uploadUrl = _d[0], setUploadUrl = _d[1];
    // const isEmptyCollection =
    //   selectedCollection === null ||
    //   (Array.isArray(selectedCollection)
    //     ? selectedCollection?.length < 1
    //     : !selectedCollection);
    var isEmptyFiles = ((_a = files === null || files === void 0 ? void 0 : files.filter(function (f) { return !f.exclude; })) === null || _a === void 0 ? void 0 : _a.length) < 1;
    var handleCreateArtwork = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res_1, resFiles, e_1, errMsg;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { createArtwork: true }));
                    setErrorMessage(__assign(__assign({}, errorMessage), { createArtwork: "" }));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    if (!(typeCollection === "new")) return [3 /*break*/, 3];
                    return [4 /*yield*/, remote_1.createArtworkLibraryCollection(selectedCollection)];
                case 2:
                    res_1 = _a.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { collections: true })); });
                    _a.label = 3;
                case 3:
                    resFiles = files === null || files === void 0 ? void 0 : files.map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        var _b, _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    _a = file.exclude !== true;
                                    if (!_a) return [3 /*break*/, 2];
                                    return [4 /*yield*/, remote_1.createArtworkLibrary(file === null || file === void 0 ? void 0 : file.uploadLog, typeCollection === "new"
                                            ? [(_b = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _b === void 0 ? void 0 : _b._id]
                                            : (_c = selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.map(function (s) { return s.value; })) === null || _c === void 0 ? void 0 : _c.filter(Boolean))];
                                case 1:
                                    _a = (_d.sent());
                                    _d.label = 2;
                                case 2: return [2 /*return*/, _a];
                            }
                        });
                    }); });
                    return [4 /*yield*/, Promise.all(resFiles)];
                case 4:
                    _a.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { artwork: true })); });
                    setFiles([]);
                    handleToggleModal("upload");
                    // setIsReload(prev => ({ ...prev, artwork: true }));
                    setSelectedCollection(null);
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    errMsg = (e_1 === null || e_1 === void 0 ? void 0 : e_1.code) < 500
                        ? e_1.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(__assign(__assign({}, errorMessage), { createArtwork: errMsg }));
                    return [3 /*break*/, 6];
                case 6:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { createArtwork: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        setFiles([]);
        handleToggleModal("upload");
        setErrorMessage({
            createArtwork: "",
            uploadUrl: ""
        });
    };
    var handleUploadByUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resUpload, res, e_2, errMsg;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { uploadUrl: true }));
                    setErrorMessage(__assign(__assign({}, errorMessage), { uploadUrl: "" }));
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, remote_1.uploadImageByUrl(uploadUrl)];
                case 2:
                    resUpload = _g.sent();
                    console.log("got result", resUpload);
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { artwork: true })); });
                    setUploadUrl("");
                    handleClose();
                    return [2 /*return*/];
                case 3:
                    _g.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { artwork: true })); });
                    setUploadUrl("");
                    handleClose();
                    return [3 /*break*/, 5];
                case 4:
                    e_2 = _g.sent();
                    errMsg = (e_2 === null || e_2 === void 0 ? void 0 : e_2.code) < 500
                        ? e_2 === null || e_2 === void 0 ? void 0 : e_2.message : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(__assign(__assign({}, errorMessage), { uploadUrl: errMsg }));
                    return [3 /*break*/, 5];
                case 5:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { uploadUrl: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var urlParams = new URLSearchParams(window.location.search);
    var isUploadByUrlEnabled = urlParams.get("isUploadByUrlEnabled");
    return (React.createElement(React.Fragment, null,
        React.createElement(common_1.ModalWrapper, { isOpen: true, onClose: function () { return handleClose(); }, style: { maxWidth: "650px" } },
            React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("div", { className: "form-group form-check" },
                            React.createElement("label", { className: "form-check-label" },
                                React.createElement("input", { type: "radio", name: "type", className: "form-check-input", disabled: (collections === null || collections === void 0 ? void 0 : collections.length) < 1, checked: typeCollection === "existing", onClick: function (e) { return setTypeCollection("existing"); } }),
                                "Upload to Existing Collection")),
                        React.createElement(common_1.CustomSelect, { isSearchable: true, isMulti: true, isDisabled: typeCollection === "new", defaultValue: [{ label: "All Artworks", value: "" }], options: collections, onChange: setSelectedCollection, placeholder: "Choose Collection" })),
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("div", { className: "form-group form-check" },
                            React.createElement("label", { className: "form-check-label" },
                                React.createElement("input", { type: "radio", name: "type", className: "form-check-input", checked: typeCollection === "new", onClick: function (e) { return setTypeCollection("new"); } }),
                                "Upload to New Collection")),
                        React.createElement("input", { type: "text", disabled: typeCollection === "existing", className: "form-control custom-form-control", onChange: function (e) { return setSelectedCollection(e.target.value); } })))),
            React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white" },
                React.createElement(common_1.Attachment, { isMultiple: true, onUpload: function (uploadedFiles) {
                        if (uploadedFiles === null || uploadedFiles === void 0 ? void 0 : uploadedFiles[0]) {
                            setFiles(function (prevFiles) { return __spreadArrays(prevFiles, uploadedFiles); });
                        }
                    } }),
                React.createElement("div", { className: "row" }, files === null || files === void 0 ? void 0 : files.map(function (file) {
                    var _a, _b;
                    return (React.createElement("div", { key: file.uuid, className: "col-12 col-md-6 text-truncate", title: file === null || file === void 0 ? void 0 : file.originalFilename },
                        React.createElement("span", { style: { cursor: "pointer" }, onClick: function () { return handleToggleExclude(file.uuid); } },
                            file.exclude === true ? (React.createElement("i", { className: "far mr-2 fa-circle" })) : (React.createElement("i", { className: "fas mr-2 txc-pink fa-check-circle" })), (_a = file === null || file === void 0 ? void 0 : file.originalFilename) === null || _a === void 0 ? void 0 :
                            _a.replace("." + ((_b = ((file === null || file === void 0 ? void 0 : file.originalFilenameExtension) || (file === null || file === void 0 ? void 0 : file.originalExt))) !== null && _b !== void 0 ? _b : ""), ""))));
                })),
                isUploadByUrlEnabled && (React.createElement("div", { className: "input-group my-3" },
                    React.createElement("div", { className: "custom-file" },
                        React.createElement("input", { type: "text", placeholder: "https://", className: "form-control rounded-pill border-dark border-right-radius-0", style: {
                                borderTopRightRadius: "0 !important",
                                borderBottomRightRadius: "0 !important"
                            }, value: uploadUrl, onChange: function (e) { return setUploadUrl(e.target.value); } })),
                    React.createElement("div", { className: "input-group-append" },
                        React.createElement(common_1.Button, { variant: "pink", disabled: isSubmitting.uploadUrl, loading: isSubmitting.uploadUrl, content: "Upload", onClick: handleUploadByUrl })))),
                React.createElement("div", { className: "text-center" },
                    React.createElement("div", { className: "text-center" },
                        React.createElement("p", { className: "" },
                            React.createElement("strong", null, "PDF, TIFF OR JPG ACCEPTED.")),
                        React.createElement("p", { className: "mb-2" }, "Maximum file size 350 MB"),
                        React.createElement("p", { className: "mb-2" },
                            "For tips on creating a perfectly-sized file, see our FAQs",
                            " ",
                            React.createElement("a", { href: "/faq" }, "here"),
                            "."),
                        React.createElement("p", { className: "mb-3" },
                            React.createElement("em", null,
                                "If you are having issues uploading your file, please contact us at",
                                " ",
                                React.createElement("a", { href: "mailto:info@printribe.com" }, "info@printribe.com")))),
                    errorMessage.createArtwork && (React.createElement("div", { className: "alert alert-danger" }, errorMessage.createArtwork)))),
            !isEmptyFiles && (React.createElement("div", { style: {
                    position: "sticky",
                    bottom: 0,
                    left: 0,
                    textAlign: "center"
                } },
                React.createElement(common_1.Button, { variant: "pink", disabled: isSubmitting.createArtwork, loading: isSubmitting.createArtwork, content: "Continue", className: "mt-3", onClick: handleCreateArtwork }))))));
};
