"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextProvider = exports.GlobalContext = void 0;
var React = __importStar(require("react"));
var types_1 = require("./types");
exports.GlobalContext = React.createContext({});
exports.ContextProvider = function (props) {
    // const [state, setState] = React.useState(initialState);
    // break down to each state, it's causing bugs it captures values from other changes
    var _a = React.useState(false), isSignatureModalOpen = _a[0], setIsSignatureModalOpen = _a[1];
    var _b = React.useState(false), isAddImageModalOpen = _b[0], setIsAddImageModalOpen = _b[1];
    var _c = React.useState(types_1.PanelNames.Scale), selectedPanel = _c[0], setSelectedPanel = _c[1];
    var _d = React.useState(types_1.PanelNames.Text), selectedImageEditorView = _d[0], setSelectedImageEditorView = _d[1];
    var _e = React.useState(""), selectedFont = _e[0], setSelectedFont = _e[1];
    var _f = React.useState("#000"), selectedFontFill = _f[0], setSelectedFontFill = _f[1];
    var _g = React.useState([]), images = _g[0], setImages = _g[1];
    var _h = React.useState([]), canvasTexts = _h[0], setCanvasTexts = _h[1];
    var _j = React.useState(1), scalePercent = _j[0], setScalePercent = _j[1];
    var _k = React.useState({}), signature = _k[0], setSignature = _k[1];
    var _l = React.useState(false), isArtworkSelected = _l[0], setIsArtworkSelected = _l[1];
    var _m = React.useState(0), artworkRotation = _m[0], setArtworkRotation = _m[1];
    var _o = React.useState(150), dpi = _o[0], setDPI = _o[1];
    // const [imageTransform, setImageTransform] = React.useState({
    //   rotateEnabled: false,
    //   resizeEnabled: false,
    //   crop: false
    // })
    var state = {
        isSignatureModalOpen: isSignatureModalOpen,
        isAddImageModalOpen: isAddImageModalOpen,
        selectedPanel: selectedPanel,
        selectedImageEditorView: selectedImageEditorView,
        selectedFont: selectedFont,
        selectedFontFill: selectedFontFill,
        images: images,
        canvasTexts: canvasTexts,
        scalePercent: scalePercent,
        signature: signature,
        isArtworkSelected: isArtworkSelected,
        artworkRotation: artworkRotation,
        dpi: dpi
        // imageTransform,
    };
    var handleAddImageClick = function () { return null; };
    var handleSetImageEditorView = function () { return null; };
    var handleSetSelectedPanel = function (v) { return setSelectedPanel(v); };
    var handleAddText = function (fill) {
        if (fill === void 0) { fill = "black"; }
        return setCanvasTexts(__spreadArrays(canvasTexts, [
            {
                text: "Text",
                fill: fill,
                isActive: false
            }
        ]));
    };
    var handlePositionText = function (idx, x, y) {
        var _a;
        var updatedCanvas = (_a = state.canvasTexts) === null || _a === void 0 ? void 0 : _a.map(function (c, index) { return (__assign(__assign({}, c), { x: index === idx ? x : c.x, y: index === idx ? y : c.y })); });
        console.log("posiito", updatedCanvas);
        setCanvasTexts(updatedCanvas);
    };
    var handleRotation = function (deg) { return setArtworkRotation(deg); };
    // {
    //   setState({
    //     ...state, canvasTexts: state.canvasTexts.concat([{
    //       text: 'Text',
    //       fill: 'black',
    //       isActive: false
    //     }])
    //   })
    // }
    var handleSelectedText = function (idx) {
        var _a;
        var updatedCanvas = (_a = state.canvasTexts) === null || _a === void 0 ? void 0 : _a.map(function (c, index) { return (__assign(__assign({}, c), { isActive: idx === index })); });
        console.log("updatedCanvas", updatedCanvas);
        setCanvasTexts(updatedCanvas);
        // setState({
        //   ...state,
        //   canvasTexts: updatedCanvas
        // })
    };
    var handleSetFont = function (font) { return setSelectedFont(font); };
    // setState({
    //   ...state,
    //   selectedFont: font
    // })
    var handleSetFontFill = function (fontFill) {
        var _a;
        var updatedCanvas = (_a = state.canvasTexts) === null || _a === void 0 ? void 0 : _a.map(function (c) { return (__assign(__assign({}, c), { fill: (c === null || c === void 0 ? void 0 : c.isActive) ? fontFill : c.fill })); });
        setCanvasTexts(updatedCanvas);
        // setState({
        //   ...state,
        //   selectedFontFill: fontFill,
        //   canvasTexts: updatedCanvas
        // })
    };
    var handleChangeText = function (v) {
        var _a;
        var updatedCanvas = (_a = state.canvasTexts) === null || _a === void 0 ? void 0 : _a.map(function (c) { return (__assign(__assign({}, c), { text: c.isActive ? v : c.text })); });
        // setState({
        //   ...state,
        //   canvasTexts: updatedCanvas
        // })
        setCanvasTexts(updatedCanvas);
    };
    var handleSignature = function (v, type) {
        var _a;
        if (type === void 0) { type = "image"; }
        var sign = (_a = {},
            _a[type] = v,
            _a);
        setSignature(__assign({}, sign));
    };
    // setState({
    //   ...state,
    //   signature: {
    //     text: type !== 'image' && v,
    //     image: type === 'image' && v
    //   }
    // })
    // const handleTransform = (transform: ImageTransform) => setImageTransform({
    //   ...imageTransform,
    //   [transform]: !state.imageTransform[transform]
    // })
    // setState({
    //   ...state,
    //   imageTransform: {
    //     ...state.imageTransform,
    //     [transform]: !state.imageTransform[transform]
    //   }
    // })
    var handleArtworkSelect = function (v) { return setIsArtworkSelected(v); };
    // {
    //   console.log('v', v);
    //   setState((prevState) => ({ ...prevState, isArtworkSelected: v }))
    // }
    var handleScaling = function (v) { return setScalePercent(v); };
    // {
    //   setState({
    //     ...state,
    //     scalePercent: v
    //   })
    // }
    var ctx = __assign(__assign({}, state), { 
        // handleAddImageClick,
        // handleSetImageEditorView,
        handleSetSelectedPanel: handleSetSelectedPanel,
        handleAddText: handleAddText,
        handleSelectedText: handleSelectedText,
        handleSignature: handleSignature,
        handleScaling: handleScaling,
        handleArtworkSelect: handleArtworkSelect,
        // handleTransform,
        setCanvasTexts: setCanvasTexts,
        setSignature: setSignature,
        handleChangeText: handleChangeText,
        handleRotation: handleRotation,
        handlePositionText: handlePositionText,
        handleSetFont: handleSetFont,
        handleSetFontFill: handleSetFontFill,
        setDPI: setDPI });
    return (React.createElement(exports.GlobalContext.Provider, { value: ctx }, props.children));
};
