"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_konva_1 = require("react-konva");
var InteractiveLayer = function (props, ref) {
    var _a, _b;
    var artworkProps = props.artworkProps, isSelected = props.isSelected, interactiveLayerRef = props.interactiveLayerRef, setArtworkProps = props.setArtworkProps, onSelect = props.onSelect;
    var shapeRef = React.useRef(null);
    var trRef = React.useRef(null);
    (_a = trRef.current) === null || _a === void 0 ? void 0 : _a.nodes([shapeRef.current]);
    (_b = trRef.current) === null || _b === void 0 ? void 0 : _b.getLayer().batchDraw();
    React.useEffect(function () {
        var _a, _b, _c;
        if (isSelected) {
            (_a = trRef === null || trRef === void 0 ? void 0 : trRef.current) === null || _a === void 0 ? void 0 : _a.nodes([shapeRef === null || shapeRef === void 0 ? void 0 : shapeRef.current]);
            (_c = (_b = trRef === null || trRef === void 0 ? void 0 : trRef.current) === null || _b === void 0 ? void 0 : _b.getLayer()) === null || _c === void 0 ? void 0 : _c.batchDraw();
        }
    }, [isSelected]);
    return (React.createElement(react_konva_1.Layer, { ref: interactiveLayerRef, visible: true, draggable: false },
        React.createElement(React.Fragment, null,
            React.createElement(react_konva_1.Rect, __assign({ visible: true, onClick: onSelect, onTap: onSelect, ref: shapeRef, opacity: 0, offset: { x: artworkProps.width / 2, y: artworkProps.height / 2 } }, artworkProps, { draggable: true, onDragMove: function (e) {
                    // console.log('drag move', e);
                    var x = e.target.attrs.x;
                    var y = e.target.attrs.y;
                    setArtworkProps(__assign(__assign({}, artworkProps), { x: x, y: y }));
                } })),
            isSelected && (React.createElement(react_konva_1.Transformer, { ref: trRef, rotateEnabled: true, resizeEnabled: true, borderEnabled: true, keepRatio: true, onTransformEnd: function (e) {
                    var node = shapeRef.current;
                    if (!node) {
                        return;
                    }
                    var scaleX = node.scaleX();
                    var scaleY = node.scaleY();
                    var rotation = node.rotation();
                    var x = node.x();
                    var y = node.y();
                    var width = node.width();
                    var height = node.height();
                    setArtworkProps(__assign(__assign({}, artworkProps), { scaleX: scaleX,
                        scaleY: scaleY,
                        rotation: rotation,
                        width: width,
                        height: height,
                        x: x,
                        y: y }));
                }, boundBoxFunc: function (oldBox, newBox) {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                    }
                    return newBox;
                } })))));
};
exports.default = InteractiveLayer;
