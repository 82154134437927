"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignaturePanel = void 0;
var React = __importStar(require("react"));
var PenSize_1 = require("./shared/PenSize");
var ColorList_1 = require("./shared/ColorList");
var SignatureModal_1 = require("./SignatureModal");
var common_1 = require("../../components/common");
var useState = React.useState;
var defaultPenSize = 12;
var defaultColor = "#000000";
var initialColorList = [
    { color: "red" },
    { color: "blue" },
    { color: "green" },
    { color: "yellow" },
    { color: "#000000", new: false },
    { color: "purple", new: false },
    { color: "pink", new: false },
    { color: "gray", new: false }
];
var backdropStyle = {
    position: "fixed",
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5
};
function SignaturePanel(props) {
    var _a = useState(defaultPenSize), penSize = _a[0], setPenSize = _a[1];
    var _b = useState(defaultColor), color = _b[0], setColor = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState(initialColorList), colorList = _d[0], setColorList = _d[1];
    var renderBackdrop = function (props) {
        return React.createElement("div", __assign({}, props, { style: backdropStyle }));
    };
    // console.log('color', color);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex flex-column " },
            React.createElement("label", null, "Pen Size"),
            React.createElement(PenSize_1.PenSize, { value: penSize, onChange: setPenSize })),
        React.createElement("div", { className: "d-flex flex-column border-left border-right px-2" },
            React.createElement("label", null, "Pen Color"),
            React.createElement(ColorList_1.ColorList, { colors: colorList, selected: color, size: 35, columns: 4, onChange: setColor, onChangePalette: setColorList })),
        React.createElement("div", { className: "d-flex align-items-center " },
            React.createElement("button", { className: "btn btn-secondary btn-sm btn-block", onClick: function () { return setIsModalOpen(true); } }, "Add Signature +")),
        isModalOpen && (React.createElement(common_1.ModalWrapper, { onClose: function () { return setIsModalOpen(false); }, isOpen: isModalOpen, style: { maxWidth: "600px" } },
            React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                React.createElement(SignatureModal_1.SignatureModal, { color: color, size: penSize, onClose: function () { return setIsModalOpen(false); } }))))));
}
exports.SignaturePanel = SignaturePanel;
