"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextPanel = void 0;
var React = __importStar(require("react"));
var ColorList_1 = require("./shared/ColorList");
var Context_1 = require("../Context");
var common_1 = require("./../../components/common");
var useState = React.useState;
var defaultColor = "#000000";
var initialColorList = [
    { color: "red" },
    { color: "blue" },
    { color: "green" },
    { color: "yellow" },
    { color: "#000000", new: false },
    { color: "purple", new: false },
    { color: "pink", new: false },
    { color: "gray", new: false }
];
var fontFamilyOptions = [
    { label: "Roboto", value: "Roboto" },
    { label: "Open Sans", value: "Open Sans" },
    { label: "Lato", value: "Lato" },
    { label: "Montserrat", value: "Montserrat" },
    { label: "Oswalk", value: "Oswalk" },
    { label: "Source Sans Pro", value: "Source Sans Pro" }
];
var fontWeightOptions = [{ label: "Regular", value: "Regular" }];
function TextPanel(props) {
    var _a = useState(defaultColor), color = _a[0], setColor = _a[1];
    var ctx = React.useContext(Context_1.GlobalContext);
    var canvasTexts = ctx.canvasTexts, handleSetFontFill = ctx.handleSetFontFill, handleAddText = ctx.handleAddText, handleSelectedText = ctx.handleSelectedText;
    var _b = useState(initialColorList), colorList = _b[0], setColorList = _b[1];
    var _c = useState(""), selectedColor = _c[0], setSelectedColor = _c[1];
    return (React.createElement("div", { className: "pt-2" },
        React.createElement("button", { className: "btn btn-sm btn-block btn-secondary", onClick: function () {
                handleAddText === null || handleAddText === void 0 ? void 0 : handleAddText(selectedColor);
            } },
            "Add Text +",
            " "),
        React.createElement("hr", null),
        React.createElement("label", null, "Font"),
        React.createElement("div", { className: "form-group" },
            React.createElement(common_1.CustomSelect, { defaultValue: fontFamilyOptions[0], options: fontFamilyOptions, onChange: function (v) { return console.log("v", v.value); } })),
        React.createElement("div", { className: "form-group" },
            React.createElement(common_1.CustomSelect, { defaultValue: fontWeightOptions[0], options: fontWeightOptions })),
        React.createElement("hr", null),
        React.createElement(ColorList_1.ColorList, { colors: colorList, selected: color, size: 35, columns: 4, onChange: function (v) {
                handleSetFontFill(v);
                setColor(v);
                setSelectedColor(v);
            }, onChangePalette: setColorList })));
}
exports.TextPanel = TextPanel;
