"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterBy = void 0;
var react_1 = __importStar(require("react"));
var underscore_string_1 = require("underscore.string");
exports.FilterBy = function (props) {
    var _a;
    var attributes = props.attributes, filterBy = props.filterBy, onFilterBy = props.onFilterBy;
    var _b = react_1.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    // console.log('sortby attributes', attributes);
    // console.log('filterBy', filterBy);
    var handleFilterBy = function (id, name) { return function (e) {
        var _a;
        var _b, _c;
        var checked = e.target.checked;
        var newFilterBy = __assign(__assign({}, filterBy), (_a = {}, _a[name] = checked
            ? __spreadArrays(((filterBy === null || filterBy === void 0 ? void 0 : filterBy[name]) || []), [id]) : (_b = filterBy[name]) === null || _b === void 0 ? void 0 : _b.filter(function (f) { return f !== id; }), _a));
        var t = (_c = Object.keys(newFilterBy)) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, cur) {
            var _a;
            if ((newFilterBy === null || newFilterBy === void 0 ? void 0 : newFilterBy[cur]) && ((_a = newFilterBy === null || newFilterBy === void 0 ? void 0 : newFilterBy[cur]) === null || _a === void 0 ? void 0 : _a.length)) {
                acc[cur] = newFilterBy[cur];
            }
            return acc;
        }, {});
        onFilterBy(t);
    }; };
    return (react_1.default.createElement("div", { className: "dropdown" },
        react_1.default.createElement("button", { className: "btn btn-default dropdown-toggle", type: "button", onClick: function () { return setIsOpen(!isOpen); } },
            react_1.default.createElement("i", { className: "fas fa-filter" }),
            " Filter by"),
        react_1.default.createElement("div", { className: "dropdown-menu " + (isOpen ? "show" : ""), "aria-labelledby": "dropdownMenuButton" },
            react_1.default.createElement("div", { className: "accordion", id: "accordionAttributes" }, (_a = Object.keys(attributes)) === null || _a === void 0 ? void 0 : _a.map(function (attribute) {
                var _a;
                return (react_1.default.createElement("div", { key: attribute },
                    react_1.default.createElement("div", { className: "", id: attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase() },
                        react_1.default.createElement("div", { className: "ic-accordion-trigger px-3 collapsed", "data-toggle": "collapse", "data-target": "#collapse" + (attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase()), "aria-expanded": "true", "aria-controls": "collapse" + (attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase()) },
                            react_1.default.createElement("span", null, underscore_string_1.humanize(attribute)),
                            react_1.default.createElement("span", { className: "ic-accordion-indicator" },
                                react_1.default.createElement("i", { className: "fas fa-angle-down" })))),
                    react_1.default.createElement("div", { id: "collapse" + (attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase()), className: "collapse px-3 py-2", "aria-labelledby": attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase(), "data-parent": "#accordionAttributes" }, (_a = attributes[attribute]) === null || _a === void 0 ? void 0 : _a.map(function (attr, index) { return (react_1.default.createElement("div", { key: index },
                        react_1.default.createElement("div", { className: "form-check" },
                            react_1.default.createElement("label", { className: "form-check-label", htmlFor: attr._id },
                                react_1.default.createElement("input", { className: "form-check-input", type: "checkbox", id: attr._id, onChange: handleFilterBy(attr._id, attribute) }), attr === null || attr === void 0 ? void 0 :
                                attr.name)))); }))));
            })))));
};
