"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtworkContainer = void 0;
var React = __importStar(require("react"));
var react_intl_1 = require("react-intl");
var Context_1 = require("./Context");
var helpers_1 = require("./../../../helpers");
var remote_1 = require("./../../../remote");
var useState = React.useState, useRef = React.useRef, useEffect = React.useEffect;
var baseUrl = "https://storage.googleapis.com/icstorage/";
function ArtworkContainer(props) {
    var _this = this;
    var _a, _b, _c;
    // const [state, setState] = useState(initialGlobalState);
    var _d = useState({
        collections: "",
        artwork: ""
    }), filter = _d[0], setFilter = _d[1];
    var _e = useState([]), items = _e[0], setItems = _e[1];
    var _f = useState([]), files = _f[0], setFiles = _f[1];
    var _g = useState({
        delete: false,
        upload: false,
        collections: false
    }), isSubmitting = _g[0], setIsSubmitting = _g[1];
    var mounted = useRef(true);
    var _h = useState([]), selectedCollection = _h[0], setSelectedCollection = _h[1];
    var _j = useState("existing"), typeCollection = _j[0], setTypeCollection = _j[1];
    var _k = useState([]), selected = _k[0], setSelected = _k[1];
    var _l = useState({
        upload: false,
        createCollection: false,
        edit: false,
        editName: false,
        removeCollection: false,
        collections: false,
        new: false,
        delete: false,
        deleteCollections: false
    }), isModalOpen = _l[0], setIsModalOpen = _l[1];
    var _m = useState({
        artwork: false,
        collections: false
    }), isReload = _m[0], setIsReload = _m[1];
    var _o = useState(false), isLoading = _o[0], setIsLoading = _o[1];
    var debouncedFilter = helpers_1.useDebounce(filter, 350);
    var _p = useState([{ value: "", label: "All Artworks" }]), collections = _p[0], setCollections = _p[1];
    var _q = useState("createdAt"), sortBy = _q[0], setSortBy = _q[1];
    var _r = useState(0), skip = _r[0], setSkip = _r[1];
    var _s = useState({
        limit: 25,
        skip: 0,
        total: 0
    }), paginationInfo = _s[0], setPaginationInfo = _s[1];
    var _t = useState([]), categories = _t[0], setCategories = _t[1];
    var loadArtworkLibraryCollection = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, newCollections, noCollections, e_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { collections: true }));
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.getArtworkLibraryCollection()];
                case 2:
                    res = _d.sent();
                    newCollections = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.map(function (d) {
                        var _a;
                        return ({
                            value: d._id,
                            label: (_a = d === null || d === void 0 ? void 0 : d.name) !== null && _a !== void 0 ? _a : ""
                        });
                    });
                    setCollections(__spreadArrays((newCollections || []), [
                        { value: "", label: "All Artworks" }
                    ]));
                    setIsReload(__assign(__assign({}, isReload), { collections: false }));
                    noCollections = ((_c = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) < 1;
                    setTypeCollection(noCollections ? "new" : "existing");
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _d.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { collections: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var loadArtworkLibrary = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, updatedItems, e_2;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    setIsLoading(true);
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.getArtworkLibrary(__assign(__assign({}, debouncedFilter), { sortBy: sortBy,
                            skip: skip }))];
                case 2:
                    res = _f.sent();
                    setPaginationInfo({
                        limit: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.limit,
                        total: (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.total,
                        skip: (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.skip
                    });
                    updatedItems = (_e = (_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.map(function (d) {
                        var _a, _b, _c, _d, _e, _f;
                        return (__assign(__assign({}, d), { itemImgUrl: (_b = (_a = d === null || d === void 0 ? void 0 : d.artworkThumbnails) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url, itemName: (_d = (_c = d === null || d === void 0 ? void 0 : d.artwork) === null || _c === void 0 ? void 0 : _c.originalFilename) === null || _d === void 0 ? void 0 : _d.replace("." + ((_e = d === null || d === void 0 ? void 0 : d.artwork) === null || _e === void 0 ? void 0 : _e.originalFilenameExtension), ""), itemFileExt: (_f = d === null || d === void 0 ? void 0 : d.artwork) === null || _f === void 0 ? void 0 : _f.originalFilenameExtension, checked: (selected === null || selected === void 0 ? void 0 : selected.find(function (s) { return s._id === d._id; })) ? true : false }));
                    });
                    setItems(updatedItems);
                    setIsReload(__assign(__assign({}, isReload), { artwork: false }));
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _f.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadCategory = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, remote_1.getCategories()];
                case 1:
                    res = _b.sent();
                    setCategories((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        loadArtworkLibrary();
        var newItems = items === null || items === void 0 ? void 0 : items.map(function (i) { return (__assign(__assign({}, i), { checked: false })); });
        setItems(newItems);
        setSelected([]);
        loadCategory();
        return function () {
            mounted.current = false;
        };
    }, [debouncedFilter]);
    useEffect(function () {
        if (isReload.collections) {
            loadArtworkLibraryCollection();
        }
        if (isReload.artwork) {
            loadArtworkLibrary();
        }
        return function () {
            mounted.current = false;
        };
    }, [isReload]);
    useEffect(function () {
        loadArtworkLibrary();
        return function () {
            mounted.current = false;
        };
    }, [sortBy, skip]);
    useEffect(function () {
        loadArtworkLibraryCollection();
        return function () {
            mounted.current = false;
        };
    }, []);
    var handleCreateCollection = function (name) { return __awaiter(_this, void 0, void 0, function () {
        var res, e_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, remote_1.createArtworkLibraryCollection(name)];
                case 1:
                    res = _b.sent();
                    setSelectedCollection((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a._id);
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (collectionId) { return __awaiter(_this, void 0, void 0, function () {
        var collections_1, res, e_4;
        var _this = this;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { upload: true }));
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    collections_1 = typeCollection === "existing"
                        ? (_a = selectedCollection === null || selectedCollection === void 0 ? void 0 : selectedCollection.map(function (s) { return s.value; })) === null || _a === void 0 ? void 0 : _a.filter(Boolean) : [collectionId];
                    res = files === null || files === void 0 ? void 0 : files.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _a = file.exclude !== true;
                                    if (!_a) return [3 /*break*/, 2];
                                    return [4 /*yield*/, remote_1.createArtworkLibrary(file === null || file === void 0 ? void 0 : file.uploadLog, collections_1)];
                                case 1:
                                    _a = (_b.sent());
                                    _b.label = 2;
                                case 2: return [2 /*return*/, _a];
                            }
                        });
                    }); });
                    return [4 /*yield*/, Promise.all(res)];
                case 2:
                    _b.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { artwork: true })); });
                    setFiles([]);
                    setIsModalOpen(__assign(__assign({}, isModalOpen), { upload: false }));
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _b.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { upload: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleToggleModal = function (name) {
        var _a;
        return setIsModalOpen(__assign(__assign({}, isModalOpen), (_a = {}, _a[name] = !isModalOpen[name], _a)));
    };
    var handleToggleExclude = function (id) {
        var updatedFiles = files === null || files === void 0 ? void 0 : files.map(function (file) {
            return file.uuid === id ? __assign(__assign({}, file), { exclude: !file.exclude }) : __assign({}, file);
        });
        setFiles(updatedFiles);
    };
    var handleSelect = function (value) { return function (e) {
        var checked = e.target.checked;
        var newItems = items === null || items === void 0 ? void 0 : items.map(function (i) {
            return i._id === value._id ? __assign(__assign({}, i), { checked: checked }) : i;
        });
        setItems(newItems);
        if (checked) {
            setSelected(__spreadArrays(selected, [value]));
        }
        else {
            var newSelect = selected === null || selected === void 0 ? void 0 : selected.filter(function (s) { return s._id !== value._id; });
            setSelected(newSelect);
        }
    }; };
    // const handleDelete = async () => {
    //   setIsSubmitting({
    //     ...isSubmitting,
    //     delete: true
    //   })
    //   try {
    //     const res: any = selected?.map(async (s: any) => (
    //       await deleteArtworkLibrary(s._id)
    //     ))
    //     const all: any = await Promise.all(res)
    //     const updateSelected = selected?.filter(s => !all.find(a => a?.data?._id === s._id))
    //     setSelected(updateSelected)
    //     setIsReload({ ...isReload, artwork: true })
    //   } catch (e) {
    //   }
    //   setIsSubmitting({
    //     ...isSubmitting,
    //     delete: false
    //   })
    // }
    var ctx = {
        filter: filter,
        files: files,
        isSubmitting: isSubmitting,
        selected: selected,
        items: items,
        setItems: setItems,
        setSortBy: setSortBy,
        collections: collections,
        setSelectedCollection: setSelectedCollection,
        typeCollection: typeCollection,
        selectedCollection: selectedCollection,
        setTypeCollection: setTypeCollection,
        isModalOpen: isModalOpen,
        categories: categories,
        paginationInfo: paginationInfo,
        setSkip: setSkip,
        isLoading: isLoading,
        setIsReload: setIsReload,
        setSelected: setSelected,
        setIsSubmitting: setIsSubmitting,
        setFiles: setFiles,
        setFilter: setFilter,
        handleSubmit: handleSubmit,
        handleCreateCollection: handleCreateCollection,
        handleToggleModal: handleToggleModal,
        handleToggleExclude: handleToggleExclude,
        handleSelect: handleSelect
        // handleDelete,
    };
    var localeToUnderScore = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.initialState) === null || _a === void 0 ? void 0 : _a.tenant) === null || _b === void 0 ? void 0 : _b.locale) === null || _c === void 0 ? void 0 : _c.replace("_", "-");
    return (React.createElement(react_intl_1.IntlProvider, { locale: localeToUnderScore, key: localeToUnderScore },
        React.createElement(Context_1.ArtworkContext.Provider, { value: ctx }, props.children)));
}
exports.ArtworkContainer = ArtworkContainer;
