"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = void 0;
var React = __importStar(require("react"));
var react_intl_1 = require("react-intl");
var Context_1 = require("./Context");
var helpers_1 = require("./../../../helpers");
var remote_1 = require("./../../../remote");
var useState = React.useState, useRef = React.useRef, useEffect = React.useEffect;
var baseUrl = "https://storage.googleapis.com/icstorage/";
function Container(props) {
    var _this = this;
    var _a, _b, _c;
    // const [state, setState] = useState(initialGlobalState);
    var _d = useState({
        categories: [],
        name: ""
    }), filter = _d[0], setFilter = _d[1];
    var _e = useState({}), groupByCategory = _e[0], setGroupByCategory = _e[1];
    var _f = useState([]), items = _f[0], setItems = _f[1];
    var _g = useState([]), files = _g[0], setFiles = _g[1];
    var _h = useState({
        delete: false,
        upload: false,
        collections: false
    }), isSubmitting = _h[0], setIsSubmitting = _h[1];
    var mounted = useRef(true);
    var _j = useState(null), selectedCollection = _j[0], setSelectedCollection = _j[1];
    var _k = useState("existing"), typeCollection = _k[0], setTypeCollection = _k[1];
    var _l = useState(1), quantity = _l[0], setQuantity = _l[1];
    var _m = useState([]), selected = _m[0], setSelected = _m[1];
    var _o = useState({
        add: false,
        edit: false,
        duplicate: false,
        new: false
    }), isModalOpen = _o[0], setIsModalOpen = _o[1];
    var _p = useState({
        product: false,
        collections: false
    }), isReload = _p[0], setIsReload = _p[1];
    var _q = useState(false), isLoading = _q[0], setIsLoading = _q[1];
    var _r = useState([]), collections = _r[0], setCollections = _r[1];
    var _s = useState("createdAt"), sortBy = _s[0], setSortBy = _s[1];
    var _t = useState([]), categories = _t[0], setCategories = _t[1];
    var _u = useState([]), categoryOptions = _u[0], setCategoryOptions = _u[1];
    var _v = useState({}), attributes = _v[0], setAttributes = _v[1];
    var _w = useState({}), filterBy = _w[0], setFilterBy = _w[1];
    var debouncedFilter = helpers_1.useDebounce(filter, 350);
    var loadArtworkLibraryCollection = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { collections: true }));
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.getProductLibraryCollection()];
                case 2:
                    res = _c.sent();
                    setCollections((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(function (d) { var _a; return ({ value: d._id, label: (_a = d === null || d === void 0 ? void 0 : d.name) !== null && _a !== void 0 ? _a : "" }); }));
                    setIsReload(__assign(__assign({}, isReload), { collections: false }));
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { collections: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var loadProductLibrary = function () { return __awaiter(_this, void 0, void 0, function () {
        var filterAttributes, res, groupByCategory_1, groupByAttributes, updatedItems, e_2;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    filterAttributes = ((_a = Object.keys(filterBy)) === null || _a === void 0 ? void 0 : _a.length) ? Object.keys(filterBy)
                        .reduce(function (acc, cur) {
                        var _a;
                        acc.push((_a = filterBy[cur]) === null || _a === void 0 ? void 0 : _a.map(function (f, index) { return "attributes[" + cur + "][" + index + "]=" + f; }).join("&"));
                        return acc;
                    }, [])
                        .join("&")
                        : undefined;
                    setIsLoading(true);
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.getProductLibrary(__assign(__assign({}, debouncedFilter), { sortBy: sortBy, filterBy: filterAttributes }))];
                case 2:
                    res = _f.sent();
                    groupByCategory_1 = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.reduce(function (acc, cur) {
                        var _a, _b, _c, _d;
                        var currentItem = __assign(__assign({}, cur), { 
                            // itemImgUrl: cur.artworkThumbnails && cur.artworkThumbnails[0] && cur.artworkThumbnails[0].url ? cur.artworkThumbnails[0].url : undefined,
                            itemName: cur.name, itemImgUrl: (_a = cur === null || cur === void 0 ? void 0 : cur.thumbnail) === null || _a === void 0 ? void 0 : _a.url, itemFileExt: null, checked: (selected === null || selected === void 0 ? void 0 : selected.find(function (s) { return s._id === cur._id; })) ? true : false });
                        if (!(acc === null || acc === void 0 ? void 0 : acc[(_b = cur === null || cur === void 0 ? void 0 : cur.category) === null || _b === void 0 ? void 0 : _b.hrCode]) && ((_c = cur === null || cur === void 0 ? void 0 : cur.category) === null || _c === void 0 ? void 0 : _c.hrCode)) {
                            acc[cur.category.hrCode] = {
                                _id: cur.category._id,
                                categoryName: cur.category.name,
                                items: [currentItem]
                            };
                        }
                        else if (acc === null || acc === void 0 ? void 0 : acc[(_d = cur === null || cur === void 0 ? void 0 : cur.category) === null || _d === void 0 ? void 0 : _d.hrCode]) {
                            acc[cur.category.hrCode] = __assign(__assign({}, (acc[cur.category.hrCode] || {})), { items: __spreadArrays((acc[cur.category.hrCode].items || []), [currentItem]) });
                        }
                        return acc;
                    }, {});
                    setGroupByCategory(groupByCategory_1);
                    groupByAttributes = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, cur) {
                        var _a;
                        (_a = cur === null || cur === void 0 ? void 0 : cur.productAttributes) === null || _a === void 0 ? void 0 : _a.reduce(function (innerAcc, innerCur) {
                            var _a;
                            var foundNewAtt = (_a = acc === null || acc === void 0 ? void 0 : acc[innerCur === null || innerCur === void 0 ? void 0 : innerCur.attribute]) === null || _a === void 0 ? void 0 : _a.find(function (attr) { return attr._id == innerCur.value._id; });
                            if (!(acc === null || acc === void 0 ? void 0 : acc[innerCur === null || innerCur === void 0 ? void 0 : innerCur.attribute])) {
                                acc[innerCur.attribute] = [
                                    {
                                        _id: innerCur.value._id,
                                        name: innerCur.value.name
                                    }
                                ];
                            }
                            else if (!foundNewAtt) {
                                acc[innerCur.attribute].push({
                                    _id: innerCur.value._id,
                                    name: innerCur.value.name
                                });
                            }
                        }, []);
                        return acc;
                    }, {});
                    if (!((_d = Object.keys(attributes)) === null || _d === void 0 ? void 0 : _d.length)) {
                        setAttributes(groupByAttributes);
                    }
                    updatedItems = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.map(function (d) {
                        var _a;
                        return (__assign(__assign({}, d), { 
                            // itemImgUrl: d.artworkThumbnails && d.artworkThumbnails[0] && d.artworkThumbnails[0].url ? d.artworkThumbnails[0].url : undefined,
                            itemImgUrl: (_a = d === null || d === void 0 ? void 0 : d.thumbnail) === null || _a === void 0 ? void 0 : _a.url, itemName: d === null || d === void 0 ? void 0 : d.name, itemFileExt: null, checked: (selected === null || selected === void 0 ? void 0 : selected.find(function (s) { return s._id === d._id; })) ? true : false }));
                    });
                    setItems(updatedItems);
                    // setItems(groupByCategory)
                    setIsReload(__assign(__assign({}, isReload), { product: false }));
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _f.sent();
                    console.log("e", e_2);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadCategory = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, options;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, remote_1.getCategories()];
                case 1:
                    res = _d.sent();
                    options = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(function (d) { return ({
                        label: d === null || d === void 0 ? void 0 : d.name,
                        value: d._id
                    }); });
                    setCategoryOptions(options);
                    setCategories((_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a;
        loadProductLibrary();
        loadCategory();
        var newItems = (_a = items) === null || _a === void 0 ? void 0 : _a.map(function (i) { return (__assign(__assign({}, (i || {})), { checked: false })); });
        setItems(newItems);
        setSelected([]);
        return function () {
            mounted.current = false;
        };
    }, [debouncedFilter]);
    useEffect(function () {
        if (isReload.product) {
            loadProductLibrary();
        }
        return function () {
            mounted.current = false;
        };
    }, [isReload]);
    useEffect(function () {
        loadProductLibrary();
        return function () {
            mounted.current = false;
        };
    }, [sortBy, filterBy]);
    useEffect(function () {
        loadArtworkLibraryCollection();
        return function () {
            mounted.current = false;
        };
    }, []);
    var handleSubmit = function (collectionId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    }); };
    var handleToggleModal = function (name) {
        var _a;
        return setIsModalOpen(__assign(__assign({}, isModalOpen), (_a = {}, _a[name] = !isModalOpen[name], _a)));
    };
    var handleToggleExclude = function (id) {
        // const updatedFiles = files?.map(file => file.uuid === id ?
        //   ({ ...file, exclude: !file.exclude })
        //   :
        //   ({ ...file })
        // )
        // setFiles(updatedFiles)
    };
    var handleSelect = function (value) { return function (e) {
        var checked = e.target.checked;
        // console.log('e.nativeEvent', e.nativeEvent);
        // const newItems = items?.map(i => i._id === value._id ? ({ ...i, checked }) : i)
        // setItems(newItems)
        // if (checked) {
        //   setSelected([...selected, value])
        // } else {
        //   const newSelect = selected?.filter(s => s._id !== value._id)
        //   setSelected(newSelect)
        // }
    }; };
    var handleDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/];
        });
    }); };
    var ctx = {
        filter: filter,
        files: files,
        isSubmitting: isSubmitting,
        selected: selected,
        groupByCategory: groupByCategory,
        items: items,
        setItems: setItems,
        categories: categories,
        categoryOptions: categoryOptions,
        collections: collections,
        setSelectedCollection: setSelectedCollection,
        typeCollection: typeCollection,
        selectedCollection: selectedCollection,
        setTypeCollection: setTypeCollection,
        isModalOpen: isModalOpen,
        quantity: quantity,
        setQuantity: setQuantity,
        attributes: attributes,
        filterBy: filterBy,
        setFilterBy: setFilterBy,
        isLoading: isLoading,
        isReload: isReload,
        setIsReload: setIsReload,
        setSelected: setSelected,
        setIsSubmitting: setIsSubmitting,
        setFiles: setFiles,
        setFilter: setFilter,
        setSortBy: setSortBy,
        handleSubmit: handleSubmit,
        handleToggleModal: handleToggleModal,
        handleToggleExclude: handleToggleExclude,
        handleSelect: handleSelect,
        handleDelete: handleDelete
    };
    var localeToUnderScore = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.initialState) === null || _a === void 0 ? void 0 : _a.tenant) === null || _b === void 0 ? void 0 : _b.locale) === null || _c === void 0 ? void 0 : _c.replace("_", "-");
    return (React.createElement(react_intl_1.IntlProvider, { locale: localeToUnderScore, key: localeToUnderScore },
        React.createElement(Context_1.ProductContext.Provider, { value: ctx }, props.children)));
}
exports.Container = Container;
