"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchItems = void 0;
function batchItems(batchSize, items) {
    return items.reduce(function (acc, cur, idx) {
        var m = idx % batchSize;
        var n = Math.ceil((idx + 1) / batchSize) - 1;
        if (!acc[n]) {
            acc[n] = [];
        }
        acc[n][m] = cur;
        return acc;
    }, []);
}
exports.batchItems = batchItems;
