"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = void 0;
var react_1 = __importStar(require("react"));
var Context_1 = require("./Context");
var common_1 = require("../../common");
exports.Filter = function (props) {
    var ctx = react_1.useContext(Context_1.ProductContext);
    var filter = ctx.filter, setFilter = ctx.setFilter, categoryOptions = ctx.categoryOptions;
    return (react_1.default.createElement("div", { className: "d-flex flex-column flex-md-row  flex-fill flex-xl-grow-0" },
        react_1.default.createElement("div", { className: " d-flex flex-fill  mb-3 mb-md-0 align-items-center p-0" },
            react_1.default.createElement("div", { className: "text-center" },
                react_1.default.createElement("label", { className: "font-weight-bold mb-0" }, "Categories ")),
            react_1.default.createElement(common_1.CustomSelect, { isSearchable: true, isMulti: true, options: categoryOptions, className: "custom-form select flex-fill mx-1", onChange: function (e) {
                    return setFilter(__assign(__assign({}, filter), { categories: e === null || e === void 0 ? void 0 : e.map(function (e) { return e.value; }) }));
                }, placeholder: "All Categories" })),
        react_1.default.createElement("div", { className: "  d-flex flex-fill ml-md-4 align-items-center" },
            react_1.default.createElement("div", { className: "text-center" },
                react_1.default.createElement("label", { className: "font-weight-bold mb-0" }, "Search ")),
            react_1.default.createElement("div", { className: "flex-fill" },
                react_1.default.createElement("div", { className: "input-group custom" },
                    react_1.default.createElement("input", { type: "text", className: "ml-1 form-control custom-form-control", onChange: function (e) { return setFilter(__assign(__assign({}, filter), { name: e.target.value })); }, placeholder: "Products" }),
                    react_1.default.createElement("div", { className: "input-group-append" },
                        react_1.default.createElement("div", { className: "input-group-text" },
                            react_1.default.createElement("i", { className: "fas fa-search" }))))))));
};
