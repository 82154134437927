"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var Context_1 = require("./Context");
var common_1 = require("./../../common");
var EditProduct_1 = require("./EditProduct");
var Filter_1 = require("./Filter");
var common_2 = require("../../common");
var ActionButtons_1 = require("./ActionButtons");
var AddToCart_1 = require("./AddToCart");
var FilterBy_1 = require("./FilterBy");
var remote_1 = require("./../../../remote");
var sortOptions = [
    { label: "Sort by: Newest", value: "createdAt" },
    { label: "Sort by: Name", value: "name" }
];
var isBrowser = function () { return typeof window !== "undefined"; };
exports.default = (function (props) {
    var _a, _b, _c, _d;
    var _e = window.__initialState, _f = _e.page, _g = _f.product, product = _g === void 0 ? {} : _g, _h = _f.category, category = _h === void 0 ? {} : _h, _j = _e.tenant, tenant = _j === void 0 ? {} : _j;
    var ctx = react_1.useContext(Context_1.ProductContext);
    if (!ctx) {
        return null;
    }
    var selected = ctx.selected, items = ctx.items, groupByCategory = ctx.groupByCategory, isModalOpen = ctx.isModalOpen, handleToggleModal = ctx.handleToggleModal, handleSelect = ctx.handleSelect, setSelected = ctx.setSelected, setItems = ctx.setItems, setSortBy = ctx.setSortBy, categories = ctx.categories, isLoading = ctx.isLoading, setIsSubmitting = ctx.setIsSubmitting, isSubmitting = ctx.isSubmitting, setIsReload = ctx.setIsReload, isReload = ctx.isReload, attributes = ctx.attributes, filterBy = ctx.filterBy, setFilterBy = ctx.setFilterBy;
    var _k = react_1.useState(""), itemOpenMenu = _k[0], setItemOpenMenu = _k[1];
    var _l = react_1.useState(""), itemHover = _l[0], setItemHover = _l[1];
    var _m = react_1.useState(1), itemQty = _m[0], setItemQty = _m[1];
    var _o = react_1.useState({
        delete: false,
        edit: false,
        add: false
    }), innerOpenModal = _o[0], setInnerOpenModal = _o[1];
    var itemNameRef = react_1.useRef(null);
    var customerSkuRef = react_1.useRef(null);
    var _p = react_1.useState(""), itemName = _p[0], setItemName = _p[1];
    var _q = react_1.useState(""), customerSku = _q[0], setCustomerSku = _q[1];
    var _r = react_1.useState({}), editItem = _r[0], setEditItem = _r[1];
    var _s = react_1.useState({
        duplicate: false,
        edit: false,
        add: false
    }), innerLoading = _s[0], setInnerLoading = _s[1];
    var _t = react_1.useState({
        edit: "",
        delete: ""
    }), errorMessage = _t[0], setErrorMessage = _t[1];
    var isButtonDisabled = (selected === null || selected === void 0 ? void 0 : selected.length) > 0;
    var selectedCollections = selected === null || selected === void 0 ? void 0 : selected.reduce(function (acc, cur) {
        var _a;
        var inner = (_a = cur === null || cur === void 0 ? void 0 : cur.collections) === null || _a === void 0 ? void 0 : _a.reduce(function (iacc, icur) {
            var innerFound = acc === null || acc === void 0 ? void 0 : acc.find(function (a) { return a.value === (icur === null || icur === void 0 ? void 0 : icur._id); });
            if (!innerFound) {
                iacc.push({ value: icur === null || icur === void 0 ? void 0 : icur._id, label: icur === null || icur === void 0 ? void 0 : icur.name });
            }
            return iacc;
        }, []);
        acc.push.apply(acc, inner);
        return acc;
    }, []);
    var foundItem = items === null || items === void 0 ? void 0 : items.find(function (i) { return i._id === itemOpenMenu; });
    var foundItemHover = items === null || items === void 0 ? void 0 : items.find(function (i) { return i._id === itemHover; });
    var handleRemove = function (item) {
        var newItems = items === null || items === void 0 ? void 0 : items.map(function (i) {
            return i._id === item._id ? __assign(__assign({}, i), { checked: false }) : i;
        });
        var newSelected = selected === null || selected === void 0 ? void 0 : selected.filter(function (s) { return s._id !== (item === null || item === void 0 ? void 0 : item._id); });
        setSelected(newSelected);
        setItems(newItems);
    };
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1, errMsg;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { delete: true }));
                    setErrorMessage(__assign(__assign({}, errorMessage), { delete: "" }));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.deleteProductLibrary(editItem._id)];
                case 2:
                    res = _a.sent();
                    // const updateSelected = selected?.filter(s => s._id !== res?.data?._id)
                    // setSelected(updateSelected)
                    setIsReload(__assign(__assign({}, isReload), { product: true }));
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    errMsg = (e_1 === null || e_1 === void 0 ? void 0 : e_1.code) < 500
                        ? e_1.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(__assign(__assign({}, errorMessage), { delete: errMsg }));
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { delete: false }));
                    setInnerOpenModal(__assign(__assign({}, innerOpenModal), { delete: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDuplicate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInnerLoading(__assign(__assign({}, innerLoading), { duplicate: true }));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.addProductLibrary({
                            product: foundItem === null || foundItem === void 0 ? void 0 : foundItem.product,
                            // customerArtwork: foundItem?.customerArtwork?._id,
                            name: ActionButtons_1.countCopy(foundItem === null || foundItem === void 0 ? void 0 : foundItem.name, ctx.items)
                        })];
                case 2:
                    res = _a.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { product: true })); });
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setInnerLoading(__assign(__assign({}, innerLoading), { duplicate: false }));
                    setItemOpenMenu("");
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res_1, updateSelected, e_3, errMsg;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setInnerLoading(__assign(__assign({}, innerLoading), { edit: true }));
                    setErrorMessage(__assign(__assign({}, errorMessage), { edit: "" }));
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.updateProductLibrary(__assign(__assign({}, (editItem || {})), { name: (_a = itemNameRef === null || itemNameRef === void 0 ? void 0 : itemNameRef.current) === null || _a === void 0 ? void 0 : _a.value, customerSku: (_b = customerSkuRef === null || customerSkuRef === void 0 ? void 0 : customerSkuRef.current) === null || _b === void 0 ? void 0 : _b.value, itemName: itemName }))];
                case 2:
                    res_1 = _c.sent();
                    setEditItem({});
                    updateSelected = selected === null || selected === void 0 ? void 0 : selected.filter(function (s) { var _a; return s._id !== ((_a = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _a === void 0 ? void 0 : _a._id); });
                    setSelected(updateSelected);
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { product: true })); });
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _c.sent();
                    errMsg = (e_3 === null || e_3 === void 0 ? void 0 : e_3.code) < 500
                        ? e_3.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(__assign(__assign({}, errorMessage), { edit: errMsg }));
                    return [3 /*break*/, 4];
                case 4:
                    setInnerOpenModal(__assign(__assign({}, innerOpenModal), { edit: false }));
                    setInnerLoading(__assign(__assign({}, innerLoading), { edit: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDownload = function (e) {
        var _a;
        window.open((_a = foundItem === null || foundItem === void 0 ? void 0 : foundItem.itemImgUrl) !== null && _a !== void 0 ? _a : "", "_blank").focus();
        setItemOpenMenu("");
        return;
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_4;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    setInnerLoading(__assign(__assign({}, innerLoading), { add: true }));
                    // console.log("foundItem", foundItem);
                    if (isBrowser() && (window === null || window === void 0 ? void 0 : window.gtag) !== undefined) {
                        window === null || window === void 0 ? void 0 : window.gtag("event", "add_to_cart", {
                            currency: tenant.currency,
                            value: (_a = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.product) === null || _a === void 0 ? void 0 : _a.price,
                            items: [
                                {
                                    item_id: (_b = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.product) === null || _b === void 0 ? void 0 : _b.key,
                                    item_name: (_d = (_c = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.product) === null || _c === void 0 ? void 0 : _c.staticName) !== null && _d !== void 0 ? _d : "",
                                    item_category: category === null || category === void 0 ? void 0 : category.name,
                                    price: (_e = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.product) === null || _e === void 0 ? void 0 : _e.price
                                }
                            ]
                        });
                    }
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.addToCart({
                            product: (_f = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.product) === null || _f === void 0 ? void 0 : _f._id,
                            quantity: itemQty,
                            images: [{ url: foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.itemImgUrl }],
                            artworkSlotsWithUrls: [
                                {
                                    sequence: "MULTIPAGEPDF",
                                    url: "https://storage.googleapis.com/icstorage/" + ((_g = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.artwork) === null || _g === void 0 ? void 0 : _g.cloudFilename)
                                }
                            ]
                        })];
                case 2:
                    res = _h.sent();
                    if (res.data.result !== undefined && res.data.result === false) {
                        ICWidgets.Client.emit("cart-item-error", {
                            message: res.data.errorMessage || res.data.message
                        });
                        // setErrorMessage(res.data.errorMessage || res.data.message)
                    }
                    else {
                        ICWidgets.Client.emit("cart-item-added", {
                            count: res.data.items.length
                        });
                        setInnerOpenModal(__assign(__assign({}, innerOpenModal), { add: true }));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _h.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setInnerLoading(__assign(__assign({}, innerLoading), { add: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("h2", { className: "mb-md-3 mt-3 font-weight-med" }, "Product Library"),
        react_1.default.createElement("div", { className: "mb-3" },
            react_1.default.createElement("div", { className: "d-flex flex-column flex-xl-row justify-content-between" },
                react_1.default.createElement(Filter_1.Filter, null),
                react_1.default.createElement("div", { className: "d-flex  mt-3 mt-xl-0 align-items-center" },
                    react_1.default.createElement(FilterBy_1.FilterBy, { attributes: attributes, filterBy: filterBy, onFilterBy: setFilterBy }),
                    react_1.default.createElement(common_1.CustomSelect, { options: sortOptions, defaultValue: sortOptions[0], className: "custom-form select select-2 flex-fill mx-1", onChange: function (e) { return setSortBy(e.value); }, placeholder: "Sort By", style: { minWidth: "180px" } }),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("button", { type: "button", className: "btn btn-pink", onClick: function () { return handleToggleModal("new"); } },
                            "New ",
                            react_1.default.createElement("i", { className: "fas fa-plus" })))))),
        isLoading ? (react_1.default.createElement("div", { className: "d-flex justify-content-center py-3" },
            react_1.default.createElement("div", { className: "spinner-border", role: "status" },
                react_1.default.createElement("span", { className: "sr-only" }, "Loading...")))) : ((_a = Object.keys(groupByCategory)) === null || _a === void 0 ? void 0 : _a.map(function (i) {
            var _a, _b, _c, _d;
            return (react_1.default.createElement("div", { key: groupByCategory[i]._id, className: "card mb-3 mb-md-4" },
                react_1.default.createElement("div", { className: "p-3" },
                    react_1.default.createElement("h3", null, (_b = (_a = groupByCategory[i]) === null || _a === void 0 ? void 0 : _a.categoryName) !== null && _b !== void 0 ? _b : "")),
                react_1.default.createElement(common_2.Grid, { items: (_c = groupByCategory[i]) === null || _c === void 0 ? void 0 : _c.items, onSelect: handleSelect, grid: 6, hasDetails: true, itemOpenMenu: itemOpenMenu, 
                    // onItemOpenMenu={(v) => {
                    //   setItemOpenMenu(v)
                    //   if (v) {
                    //     const foundItem = items?.find(i => i._id === v)
                    //     setEditItem(foundItem)
                    //   }
                    // }}
                    onHover: function (v) {
                        setItemHover(v);
                        if (v) {
                            var foundItem_1 = items === null || items === void 0 ? void 0 : items.find(function (i) { return i._id === v; });
                            setEditItem(foundItem_1);
                            return;
                        }
                        setItemQty(1);
                        setItemOpenMenu("");
                    }, hoverItem: react_1.default.createElement("div", { className: "grid-item-hover" },
                        react_1.default.createElement("div", { className: "grid-item-context-menu" },
                            react_1.default.createElement("button", { type: "button", className: "grid-item-context-menu-trigger", onClick: function () {
                                    return setItemOpenMenu(function (prev) { return (prev ? "" : itemHover); });
                                } },
                                react_1.default.createElement("i", { className: "fas fa-ellipsis-v" })),
                            react_1.default.createElement("nav", { className: "grid-item-context-nav" },
                                react_1.default.createElement("button", { className: "grid-item-context-menu-item", type: "button", onClick: function () {
                                        setInnerOpenModal(__assign(__assign({}, innerOpenModal), { edit: true }));
                                    } },
                                    react_1.default.createElement("i", { className: "fas fa-pen" }),
                                    " Edit"),
                                react_1.default.createElement("button", { className: "grid-item-context-menu-item", type: "button", onClick: handleDuplicate },
                                    react_1.default.createElement("i", { className: "fas fa-clone" }),
                                    " Duplicate"),
                                react_1.default.createElement("button", { className: "grid-item-context-menu-item", type: "button", onClick: function (e) {
                                        e.preventDefault();
                                        setInnerOpenModal(__assign(__assign({}, innerOpenModal), { delete: true }));
                                    } },
                                    react_1.default.createElement("i", { className: "fas fa-trash-alt" }),
                                    " Delete"),
                                react_1.default.createElement("button", { className: "grid-item-context-menu-item", type: "button", onClick: handleDownload },
                                    react_1.default.createElement("i", { className: "fas fa-download" }),
                                    " Download"))),
                        react_1.default.createElement("div", { className: "grid-item-quick-add" },
                            react_1.default.createElement("div", { className: "mb-3" },
                                react_1.default.createElement(common_1.Button, { variant: "primary-alt2", disabled: (_d = foundItemHover === null || foundItemHover === void 0 ? void 0 : foundItemHover.product) === null || _d === void 0 ? void 0 : _d.isOutOfStockManual, content: "Add to cart", onClick: handleSubmit })),
                            react_1.default.createElement("div", { className: "" },
                                react_1.default.createElement("div", { className: "custom custom-form-number" },
                                    react_1.default.createElement("button", { type: "button", className: "btn decrease", onClick: function () {
                                            return itemQty > 1 ? setItemQty(Number(itemQty) - 1) : null;
                                        } },
                                        " ",
                                        "-",
                                        " "),
                                    react_1.default.createElement("input", { type: "text", className: "form-control", value: itemQty, onChange: function (e) {
                                            return setItemQty(Number(e.target.value) || 1);
                                        } }),
                                    react_1.default.createElement("button", { type: "button", className: "btn increase", onClick: function () { return setItemQty(Number(itemQty) + 1); } }, "+"))))) }, !(items === null || items === void 0 ? void 0 : items.length) && (react_1.default.createElement("div", { className: "text-center py-3" },
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("i", { className: "far fa-file-image fa-3x" })),
                    "Nothing found")))));
        })),
        innerOpenModal.add && (react_1.default.createElement(common_1.ModalWrapper, { isOpen: true, onClose: function () {
                return setInnerOpenModal(__assign(__assign({}, innerOpenModal), { add: false }));
            }, style: { maxWidth: "450px" } },
            react_1.default.createElement("div", { className: "rounded-corner p-3 py-md-5 bg-white my-3 my-md-4 position-relative" },
                react_1.default.createElement("span", { className: "icon-indicator cart center" }),
                react_1.default.createElement("h4", { className: "text-center mb-0" }, "Product has been added to your cart!")),
            react_1.default.createElement("h4", { className: "text-center my-4" }, "What would you like to do now?"),
            react_1.default.createElement("div", { className: "text-center" },
                react_1.default.createElement(common_1.Button, { className: "mx-2", onClick: function () {
                        return setInnerOpenModal(__assign(__assign({}, innerOpenModal), { add: false }));
                    }, content: "Close" }),
                react_1.default.createElement(common_1.Button, { variant: "pink", className: "mx-2", onClick: function () { return (window.location.href = "/cart"); }, content: "View Cart" })))),
        (isModalOpen === null || isModalOpen === void 0 ? void 0 : isModalOpen.new) && (react_1.default.createElement(common_2.AddProduct, { categories: categories, onClose: function () { return handleToggleModal("new"); } })),
        innerOpenModal.edit && (react_1.default.createElement(common_1.ModalWrapper, { isOpen: innerOpenModal.edit, onClose: function () {
                return setInnerOpenModal(__assign(__assign({}, innerOpenModal), { edit: false }));
            }, style: {
                maxWidth: "500px"
            } },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("h3", { className: "text-center" }, "Edit Product"),
                errorMessage.edit && (react_1.default.createElement("div", { className: "alert alert-danger" }, errorMessage.edit)),
                react_1.default.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                    react_1.default.createElement("div", { className: "form-group" },
                        react_1.default.createElement("label", null, "Name"),
                        react_1.default.createElement("input", { type: "text", ref: itemNameRef, className: "form-control custom-form-control", defaultValue: (_b = foundItem === null || foundItem === void 0 ? void 0 : foundItem.itemName) !== null && _b !== void 0 ? _b : "" })),
                    react_1.default.createElement("div", { className: "form-group" },
                        react_1.default.createElement("label", null, "Your SKU"),
                        react_1.default.createElement("input", { type: "text", ref: customerSkuRef, className: "form-control custom-form-control", defaultValue: (_c = foundItem === null || foundItem === void 0 ? void 0 : foundItem.customerSku) !== null && _c !== void 0 ? _c : "" })),
                    react_1.default.createElement("div", { className: "text-center" },
                        react_1.default.createElement(common_1.Button, { variant: "pink", disabled: innerLoading.edit, loading: innerLoading.edit, className: "mx-2", content: "Edit", onClick: handleEdit })))))),
        innerOpenModal.delete && (react_1.default.createElement(common_1.ModalWrapper, { isOpen: innerOpenModal.delete, onClose: function () {
                return setInnerOpenModal(__assign(__assign({}, innerOpenModal), { delete: false }));
            }, style: {
                maxWidth: "500px"
            } },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("h3", { className: "text-center" }, "Delete Product"),
                errorMessage.delete && (react_1.default.createElement("div", { className: "alert alert-danger" }, errorMessage.delete)),
                react_1.default.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                    react_1.default.createElement("div", { className: "text-center" },
                        isSubmitting.delete ? (react_1.default.createElement("p", null, "Deleting...")) : (react_1.default.createElement("p", null,
                            "Are you sure you want to delete ", (_d = foundItem === null || foundItem === void 0 ? void 0 : foundItem.itemName) !== null && _d !== void 0 ? _d : "",
                            "?")),
                        react_1.default.createElement(common_1.Button, { variant: "primary", className: "mx-2", content: "Close", onClick: function () {
                                return setInnerOpenModal(__assign(__assign({}, innerOpenModal), { delete: false }));
                            } }),
                        react_1.default.createElement(common_1.Button, { variant: "pink", disabled: isSubmitting.delete, loading: isSubmitting.delete, className: "mx-2", content: "Delete", onClick: handleDelete })))))),
        react_1.default.createElement(AddToCart_1.AddToCart, null),
        react_1.default.createElement(EditProduct_1.EditProduct, null)));
});
