"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var Context_1 = require("./../Context");
var common_1 = require("./../../common");
var ImageEditor_1 = require("./../../../ImageEditor");
var helpers_1 = require("../../../helpers");
var remote_1 = require("../../../remote");
var baseUrl = "https://storage.googleapis.com/icstorage/";
exports.default = (function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var onClose = props.onClose;
    var _l = window.__initialState.page.category, category = _l === void 0 ? {} : _l;
    var _m = react_1.useState(0), loadingPercentage = _m[0], setLoadingPercentage = _m[1];
    var _o = react_1.useState(false), isOpenModal = _o[0], setIsOpenModal = _o[1];
    var context = react_1.useContext(Context_1.ProductContext);
    var selectedProduct = context.selectedProduct, artworkPanel = context.artworkPanel, attributes = context.attributes, selectedArtwork = context.selectedArtwork, selectedAttributes = context.selectedAttributes, setSaveArtwork = context.setSaveArtwork, setSaveArtworkThumb = context.setSaveArtworkThumb, hasResolutionWarning = context.hasResolutionWarning, setHasResolutionWarning = context.setHasResolutionWarning, imageEditorCtx = context.imageEditorCtx, setImageEditorCtx = context.setImageEditorCtx, setArtworkProps = context.setArtworkProps, refHandleExport = context.refHandleExport, setSaveArtworkDetail = context.setSaveArtworkDetail;
    // const foundAttr = selectedProduct?.attributes?.find(attr => attr.attribute === "SIZE")
    var foundSize = (_a = attributes === null || attributes === void 0 ? void 0 : attributes["SIZE"]) === null || _a === void 0 ? void 0 : _a.find(function (attr) { return attr._id === (selectedAttributes === null || selectedAttributes === void 0 ? void 0 : selectedAttributes["SIZE"]); });
    var foundMask = (_b = attributes === null || attributes === void 0 ? void 0 : attributes["MOULDING"]) === null || _b === void 0 ? void 0 : _b.find(function (attr) { return attr._id === (selectedAttributes === null || selectedAttributes === void 0 ? void 0 : selectedAttributes["MOULDING"]); });
    var foundMatboard = (_c = attributes === null || attributes === void 0 ? void 0 : attributes["MOUNTING"]) === null || _c === void 0 ? void 0 : _c.find(function (attr) { return attr._id === (selectedAttributes === null || selectedAttributes === void 0 ? void 0 : selectedAttributes["MOUNTING"]); });
    var foundOrientation = (_d = attributes === null || attributes === void 0 ? void 0 : attributes["ORIENTATION"]) === null || _d === void 0 ? void 0 : _d.find(function (attr) { return attr._id === (selectedAttributes === null || selectedAttributes === void 0 ? void 0 : selectedAttributes["ORIENTATION"]); });
    var rotation = foundOrientation && foundOrientation.hrCode === "landscape" ? 90 : 0;
    var artworkUrl = ((_e = selectedArtwork === null || selectedArtwork === void 0 ? void 0 : selectedArtwork.artwork) === null || _e === void 0 ? void 0 : _e.cloudFilename) ? "https://storage.googleapis.com/" + ((_f = selectedArtwork === null || selectedArtwork === void 0 ? void 0 : selectedArtwork.artwork) === null || _f === void 0 ? void 0 : _f.cloudBucket) + "/" + ((_g = selectedArtwork === null || selectedArtwork === void 0 ? void 0 : selectedArtwork.artwork) === null || _g === void 0 ? void 0 : _g.cloudFilename)
        : "";
    var maskUrl = "";
    if ((foundMask === null || foundMask === void 0 ? void 0 : foundMask.hrCode) && (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode)) {
        maskUrl = "https://storage.googleapis.com/ic-printribe-editor/" + (category === null || category === void 0 ? void 0 : category.hrCode) + "/" + (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode) + "/01%20Frames/" + (foundMask === null || foundMask === void 0 ? void 0 : foundMask.hrCode) + "_" + (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode) + ".png";
    }
    else if ((category === null || category === void 0 ? void 0 : category.hrCode) === "rolled-prints") {
        var foundPaper = (_h = attributes === null || attributes === void 0 ? void 0 : attributes["PAPER"]) === null || _h === void 0 ? void 0 : _h.find(function (attr) { return attr._id === (selectedAttributes === null || selectedAttributes === void 0 ? void 0 : selectedAttributes["PAPER"]); });
        maskUrl = "https://storage.googleapis.com/ic-printribe-editor/" + (category === null || category === void 0 ? void 0 : category.hrCode) + "/" + (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode) + "/01%20Frames/" + (foundPaper === null || foundPaper === void 0 ? void 0 : foundPaper.hrCode) + "_" + (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode) + ".png";
        // maskUrl = `https://storage.googleapis.com/ic-printribe-editor/${category?.hrCode}/${foundSize?.name}/01%20Frames/art-paper_${foundSize?.name}.png`;
    }
    var matboardUrl = "";
    var hasMatboard = (foundMatboard === null || foundMatboard === void 0 ? void 0 : foundMatboard.hrCode) !== "no-matboard-full-bleed-to-edges" &&
        (foundMatboard === null || foundMatboard === void 0 ? void 0 : foundMatboard.hrCode) !== "no-border";
    var isFloatMount = (foundMatboard === null || foundMatboard === void 0 ? void 0 : foundMatboard.hrCode) === "float-mount";
    var isWhiteBorder = (foundMatboard === null || foundMatboard === void 0 ? void 0 : foundMatboard.hrCode) === "white-border";
    if (hasMatboard) {
        var matboard = "02%20Matboards";
        var file = "matboard";
        if (isFloatMount) {
            matboard = "03%20Float%20Mounts";
            file = "float-mount";
        }
        else if (isWhiteBorder) {
            file = "border";
            matboard = "04%20White%20Border";
        }
        matboardUrl = "https://storage.googleapis.com/ic-printribe-editor/" + (category === null || category === void 0 ? void 0 : category.hrCode) + "/" + (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode) + "/" + matboard + "/" + (foundMask === null || foundMask === void 0 ? void 0 : foundMask.hrCode) + "_" + file + "_" + (foundSize === null || foundSize === void 0 ? void 0 : foundSize.hrCode) + ".png";
    }
    // if (hasMatboard && foundMatboard?.hrCode === "float-mount") {
    //   matboardUrl =
    //     foundMask?.hrCode && foundSize?.hrCode
    //       ? `https://storage.googleapis.com/ic-printribe-editor/${category?.hrCode}/${foundSize?.hrCode}/03%20Float%20Mounts/${foundMask?.hrCode}_float-mount_${foundSize?.hrCode}.png`
    //       : "";
    // } else if (hasMatboard) {
    //   matboardUrl =
    //     foundMask?.hrCode && foundSize?.hrCode
    //       ? `https://storage.googleapis.com/ic-printribe-editor/${category?.hrCode}/${foundSize?.hrCode}/02%20Matboards/${foundMask?.hrCode}_matboard_${foundSize?.hrCode}.png`
    //       : "";
    // }
    var metaPrintWidth = helpers_1.arrayFinder(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.metadata, "key", "printFileWidthCm");
    var metaFrameWidth = helpers_1.arrayFinder(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.metadata, "key", "framedWidthCm");
    var metaPrintHeight = helpers_1.arrayFinder(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.metadata, "key", "printFileHeightCm");
    var metaFrameHeight = helpers_1.arrayFinder(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.metadata, "key", "framedHeightCm");
    var metaStretchWidth = helpers_1.arrayFinder(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.metadata, "key", "stretchedImageWidthCm");
    var metaStretchHeight = helpers_1.arrayFinder(selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.metadata, "key", "stretchedImageHeightCm");
    var x = Number(metaPrintWidth === null || metaPrintWidth === void 0 ? void 0 : metaPrintWidth.value) / Number(metaFrameWidth === null || metaFrameWidth === void 0 ? void 0 : metaFrameWidth.value);
    var y = Number(metaPrintHeight === null || metaPrintHeight === void 0 ? void 0 : metaPrintHeight.value) / Number(metaFrameHeight === null || metaFrameHeight === void 0 ? void 0 : metaFrameHeight.value);
    var paddingX = x === Infinity ? 0 : x;
    var paddingY = y === Infinity ? 0 : y;
    var dims = {
        width: Number((metaPrintWidth === null || metaPrintWidth === void 0 ? void 0 : metaPrintWidth.value) || (foundSize === null || foundSize === void 0 ? void 0 : foundSize.width)),
        height: Number((metaPrintHeight === null || metaPrintHeight === void 0 ? void 0 : metaPrintHeight.value) || (foundSize === null || foundSize === void 0 ? void 0 : foundSize.height))
    };
    // if (metaStretchWidth?.value) {
    //   dims.width = Number(metaFrameWidth?.value);
    // }
    // if (metaStretchHeight?.value) {
    //   dims.height = Number(metaFrameHeight?.value);
    // }
    var _p = react_1.useState(""), responseMessage = _p[0], setResponseMessage = _p[1];
    var _q = react_1.useState(false), isSubmitting = _q[0], setIsSubmitting = _q[1];
    var refHandleInner = react_1.default.useRef();
    var handleClick = function (type) {
        if (type === void 0) { type = "artwork"; }
        return __awaiter(void 0, void 0, void 0, function () {
            var artworkBlob, artworkThumbBlob, refContext, refContextPreview, signature, artworkProps, frameProps_1, canvasTexts, previewContainerDims, previewArtworkProps, previewFrameProps_1, s, a, updatedCanvasTexts, frameW, frameH, artX, artY, percValue, resAll, artwork, thumb, e_1;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14;
            return __generator(this, function (_15) {
                switch (_15.label) {
                    case 0:
                        console.log("handleClick type", type);
                        return [4 /*yield*/, ((_a = refHandleInner === null || refHandleInner === void 0 ? void 0 : refHandleInner.current) === null || _a === void 0 ? void 0 : _a.export(type))];
                    case 1:
                        artworkBlob = _15.sent();
                        if (!(type === "artwork")) return [3 /*break*/, 3];
                        return [4 /*yield*/, ((_b = refHandleInner === null || refHandleInner === void 0 ? void 0 : refHandleInner.current) === null || _b === void 0 ? void 0 : _b.export("thumb"))];
                    case 2:
                        artworkThumbBlob = _15.sent();
                        _15.label = 3;
                    case 3: return [4 /*yield*/, ((_c = refHandleInner === null || refHandleInner === void 0 ? void 0 : refHandleInner.current) === null || _c === void 0 ? void 0 : _c.ctx())];
                    case 4:
                        refContext = _15.sent();
                        return [4 /*yield*/, ((_d = refHandleExport === null || refHandleExport === void 0 ? void 0 : refHandleExport.current) === null || _d === void 0 ? void 0 : _d.ctx())];
                    case 5:
                        refContextPreview = _15.sent();
                        if (refContext) {
                            signature = refContext.signature, artworkProps = refContext.artworkProps, frameProps_1 = refContext.frameProps, canvasTexts = refContext.canvasTexts;
                            previewContainerDims = refContextPreview.containerDims, previewArtworkProps = refContextPreview.artworkProps, previewFrameProps_1 = refContextPreview.frameProps;
                            s = helpers_1.positionAbsolute(signature, previewFrameProps_1, frameProps_1);
                            a = helpers_1.positionAbsolute(artworkProps, previewFrameProps_1, frameProps_1);
                            updatedCanvasTexts = canvasTexts === null || canvasTexts === void 0 ? void 0 : canvasTexts.map(function (c) {
                                var _a = helpers_1.positionAbsolute(frameProps_1, previewFrameProps_1, c), x = _a.x, y = _a.y;
                                return __assign(__assign({}, c), { x: x,
                                    y: y });
                            });
                            setArtworkProps(artworkProps);
                            frameW = frameProps_1.width / previewFrameProps_1.width;
                            frameH = frameProps_1.height / previewFrameProps_1.height;
                            artX = (artworkProps.x - previewFrameProps_1.x) / frameW;
                            if (frameW === 1) {
                                artX = artworkProps.x - frameProps_1.x + previewFrameProps_1.x;
                            }
                            else if (previewFrameProps_1.x === 0) {
                                artX = a.x / frameW;
                            }
                            artY = artworkProps.y / frameH + previewFrameProps_1.y;
                            // console.log("artX", artX, "artY", artY);
                            setImageEditorCtx(__assign(__assign({}, refContext), { preview: {
                                    signature: __assign(__assign({}, signature), { scaleY: frameH === 1
                                            ? signature.scaleY
                                            : ((_e = signature === null || signature === void 0 ? void 0 : signature.scaleY) !== null && _e !== void 0 ? _e : 1) / frameH, scaleX: frameW === 1
                                            ? signature.scaleX
                                            : ((_f = signature === null || signature === void 0 ? void 0 : signature.scaleX) !== null && _f !== void 0 ? _f : 1) / frameW, fontSize: (signature === null || signature === void 0 ? void 0 : signature.fontSize) / frameH, x: s.x / frameW, y: signature.y / frameH + previewFrameProps_1.y }),
                                    canvasTexts: updatedCanvasTexts,
                                    artworkProps: __assign(__assign({}, previewArtworkProps), { scaleX: frameW === 1 ? artworkProps.scaleX : artworkProps.scaleX / frameW, scaleY: frameH === 1 ? artworkProps.scaleY : artworkProps.scaleY / frameH, x: artX, y: artY }),
                                    frameProps: previewFrameProps_1,
                                    containerDims: previewContainerDims
                                } }));
                        }
                        if (type === "download") {
                            helpers_1.downloadBlob(artworkBlob);
                            return [2 /*return*/];
                        }
                        setIsSubmitting(true);
                        setResponseMessage("");
                        percValue = Math.floor(Math.random() * 95);
                        setLoadingPercentage(percValue);
                        _15.label = 6;
                    case 6:
                        _15.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, Promise.all((_g = [artworkBlob, artworkThumbBlob]) === null || _g === void 0 ? void 0 : _g.map(function (a) { return remote_1.uploadImage(a); }))];
                    case 7:
                        resAll = _15.sent();
                        setLoadingPercentage(100);
                        if ((_h = resAll[0]) === null || _h === void 0 ? void 0 : _h.data) {
                            artwork = (_p = (_o = (_m = (_l = (_k = (_j = resAll[0]) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.response) === null || _l === void 0 ? void 0 : _l.files) === null || _m === void 0 ? void 0 : _m.files) === null || _o === void 0 ? void 0 : _o[0]) !== null && _p !== void 0 ? _p : (_t = (_s = (_r = (_q = resAll[0]) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.files) === null || _s === void 0 ? void 0 : _s.files) === null || _t === void 0 ? void 0 : _t[0];
                            thumb = (_y = (_x = (_w = (_v = (_u = resAll[1]) === null || _u === void 0 ? void 0 : _u.data) === null || _v === void 0 ? void 0 : _v.files) === null || _w === void 0 ? void 0 : _w.files) === null || _x === void 0 ? void 0 : _x[0]) !== null && _y !== void 0 ? _y : (_3 = (_2 = (_1 = (_0 = (_z = resAll[1]) === null || _z === void 0 ? void 0 : _z.data) === null || _0 === void 0 ? void 0 : _0.response) === null || _1 === void 0 ? void 0 : _1.files) === null || _2 === void 0 ? void 0 : _2.files) === null || _3 === void 0 ? void 0 : _3[0];
                            setSaveArtworkDetail({
                                artwork: artwork,
                                thumb: thumb
                            });
                            setSaveArtwork((_9 = (_8 = (_7 = (_6 = (_5 = (_4 = resAll[0]) === null || _4 === void 0 ? void 0 : _4.data) === null || _5 === void 0 ? void 0 : _5.response) === null || _6 === void 0 ? void 0 : _6.files) === null || _7 === void 0 ? void 0 : _7.files) === null || _8 === void 0 ? void 0 : _8[0]) === null || _9 === void 0 ? void 0 : _9.cloudFilename);
                            setSaveArtworkThumb((_14 = (_13 = (_12 = (_11 = (_10 = resAll[1]) === null || _10 === void 0 ? void 0 : _10.data) === null || _11 === void 0 ? void 0 : _11.files) === null || _12 === void 0 ? void 0 : _12.files) === null || _13 === void 0 ? void 0 : _13[0]) === null || _14 === void 0 ? void 0 : _14.cloudFilename);
                            onClose();
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _15.sent();
                        return [3 /*break*/, 9];
                    case 9:
                        setLoadingPercentage(0);
                        setIsSubmitting(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    // console.log({ hasResolutionWarning, dims });
    return (react_1.default.createElement(common_1.ModalWrapper, { isOpen: true, onClose: onClose, style: {
            height: "100%",
            maxHeight: "85vh",
            maxWidth: "1350px"
        }, className: "image-editor" },
        react_1.default.createElement("h3", { className: "text-center" }, "Edit Artwork"),
        react_1.default.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
            react_1.default.createElement("div", { className: "mb-3" },
                react_1.default.createElement(ImageEditor_1.ImageEditor, { artwork: artworkUrl, maskUrl: maskUrl, matboardUrl: matboardUrl, stretchDims: {
                        width: Number(metaStretchWidth === null || metaStretchWidth === void 0 ? void 0 : metaStretchWidth.value),
                        height: Number(metaStretchHeight === null || metaStretchHeight === void 0 ? void 0 : metaStretchHeight.value)
                    }, productDims: __assign(__assign({}, dims), { scaleX: (_j = imageEditorCtx === null || imageEditorCtx === void 0 ? void 0 : imageEditorCtx.artworkProps) === null || _j === void 0 ? void 0 : _j.scaleX, scaleY: (_k = imageEditorCtx === null || imageEditorCtx === void 0 ? void 0 : imageEditorCtx.artworkProps) === null || _k === void 0 ? void 0 : _k.scaleY }), paddings: {
                        x: paddingX,
                        y: paddingY
                    }, rotation: rotation, outerArtworkProps: imageEditorCtx === null || imageEditorCtx === void 0 ? void 0 : imageEditorCtx.artworkProps, onResolutionWarning: setHasResolutionWarning, ref: refHandleInner, artworkPanel: artworkPanel, canvasTexts: imageEditorCtx === null || imageEditorCtx === void 0 ? void 0 : imageEditorCtx.canvasTexts, signature: imageEditorCtx === null || imageEditorCtx === void 0 ? void 0 : imageEditorCtx.signature, isCanvass: category.hrCode === "classic-canvas", isMaskOnFrame: (category === null || category === void 0 ? void 0 : category.hrCode) === "rolled-prints" })),
            react_1.default.createElement("div", { className: "progress" },
                react_1.default.createElement("div", { className: "progress-bar progress-bar-striped progress-bar-animated", role: "progressbar", "aria-valuenow": loadingPercentage, "aria-valuemin": 6, "aria-valuemax": 100, style: { width: loadingPercentage + "%" } },
                    react_1.default.createElement("span", { className: "sr-only" },
                        loadingPercentage,
                        "% complete")))),
        react_1.default.createElement("div", { style: {
                position: "sticky",
                bottom: 0,
                maxWidth: "350px",
                marginLeft: "auto"
            } },
            responseMessage && (react_1.default.createElement("div", { className: "alert alert-success" }, responseMessage)),
            react_1.default.createElement("div", { className: "d-flex justify-content-between py-4" },
                react_1.default.createElement("div", { className: "zoom-indicator" }),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(common_1.Button, { variant: "primary", disabled: isSubmitting, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, ((_a = refHandleExport === null || refHandleExport === void 0 ? void 0 : refHandleExport.current) === null || _a === void 0 ? void 0 : _a.handleHideFrame(false))];
                                    case 1:
                                        _b.sent();
                                        handleClick("download");
                                        return [2 /*return*/];
                                }
                            });
                        }); }, content: "Download Thumbnail" }),
                    react_1.default.createElement(common_1.Button, { variant: "primary", disabled: isSubmitting, loading: isSubmitting, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var dpi;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0: return [4 /*yield*/, ((_a = refHandleInner === null || refHandleInner === void 0 ? void 0 : refHandleInner.current) === null || _a === void 0 ? void 0 : _a.checkForResolution())];
                                    case 1:
                                        dpi = _c.sent();
                                        if (!(dpi > 149)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, ((_b = refHandleExport === null || refHandleExport === void 0 ? void 0 : refHandleExport.current) === null || _b === void 0 ? void 0 : _b.handleHideFrame(false))];
                                    case 2:
                                        _c.sent();
                                        handleClick("artwork");
                                        return [3 /*break*/, 4];
                                    case 3:
                                        setIsOpenModal(true);
                                        _c.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }, content: "Save", className: "mx-2" })))),
        isOpenModal && (react_1.default.createElement(common_1.ModalWrapper, { isOpen: true, onClose: function () { return setIsOpenModal(false); }, "aria-labelledby": "modal-label", style: { maxWidth: "450px" } },
            react_1.default.createElement("div", { style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    minHeight: "150px"
                } },
                react_1.default.createElement("div", { className: "alert alert-warning mt-3" }, "It looks like your photo resolution is too low to print."),
                react_1.default.createElement("div", { className: "text-right" },
                    react_1.default.createElement(common_1.Button, { className: "", variant: "outline-secondary", onClick: function () { return setIsOpenModal(false); } }, "Fix"),
                    react_1.default.createElement(common_1.Button, { className: "ml-3", loading: isSubmitting, disabled: isSubmitting, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0: return [4 /*yield*/, ((_a = refHandleExport === null || refHandleExport === void 0 ? void 0 : refHandleExport.current) === null || _a === void 0 ? void 0 : _a.handleHideFrame(false))];
                                    case 1:
                                        _b.sent();
                                        setIsOpenModal(false);
                                        handleClick("artwork");
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, "Ignore and Save")))))));
});
