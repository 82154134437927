"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditProduct = void 0;
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var remote_1 = require("../../../remote");
var common_1 = require("../../common");
var useContext = React.useContext, useState = React.useState;
var baseUrl = "https://storage.googleapis.com/icstorage/";
exports.EditProduct = function (props) {
    var _a;
    var ctx = useContext(Context_1.ProductContext);
    var selected = ctx.selected, isModalOpen = ctx.isModalOpen, setIsReload = ctx.setIsReload, setSelected = ctx.setSelected, handleToggleModal = ctx.handleToggleModal;
    var _b = useState({}), products = _b[0], setProducts = _b[1];
    var _c = useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState({
        product: false
    }), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var handleChange = function (name, product) {
        var _a;
        return setProducts(__assign(__assign({}, products), (_a = {}, _a[product._id] = __assign(__assign({}, product), { name: name }), _a)));
    };
    var handleUpdateproduct = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, all_1, updateSelected, e_1, errMsg;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { product: true }));
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    res = (_a = Object.keys(products)) === null || _a === void 0 ? void 0 : _a.map(function (key) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, remote_1.updateProductLibrary(products[key])];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    }); }); });
                    return [4 /*yield*/, Promise.all(res)];
                case 2:
                    all_1 = _b.sent();
                    updateSelected = selected === null || selected === void 0 ? void 0 : selected.filter(function (s) { return !all_1.find(function (a) { var _a; return ((_a = a === null || a === void 0 ? void 0 : a.data) === null || _a === void 0 ? void 0 : _a._id) === s._id; }); });
                    setSelected(updateSelected);
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { product: true })); });
                    setProducts({});
                    handleToggleModal("edit");
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    errMsg = (e_1 === null || e_1 === void 0 ? void 0 : e_1.code) < 500
                        ? e_1.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(errMsg);
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { product: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null, isModalOpen.edit && (React.createElement(common_1.ModalWrapper, { isOpen: true, onClose: function () {
            handleToggleModal("edit");
            setIsSubmitting({
                product: false
            });
        }, style: { maxWidth: "500px" } },
        React.createElement("h3", { className: "text-center" }, "Edit product"),
        React.createElement("div", { className: "sticky-header rounded-corner p-3 p-md-4 bg-white mb-3 text-center" },
            React.createElement(common_1.Button, { content: "Update products", disabled: isSubmitting.product || ((_a = Object.keys(products)) === null || _a === void 0 ? void 0 : _a.length) < 1, loading: isSubmitting.product, onClick: handleUpdateproduct })),
        errorMessage && (React.createElement("div", { className: "alert alert-danger" }, errorMessage)), selected === null || selected === void 0 ? void 0 :
        selected.map(function (s, idx) {
            var _a;
            return (React.createElement("div", { key: s._id, className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-4" },
                        React.createElement("img", { src: s === null || s === void 0 ? void 0 : s.itemImgUrl, alt: (_a = s === null || s === void 0 ? void 0 : s.name) !== null && _a !== void 0 ? _a : "" })),
                    React.createElement("div", { className: "col-12 col-md" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", null, "Name"),
                            React.createElement("input", { type: "text", className: "form-control custom-form-control", defaultValue: s === null || s === void 0 ? void 0 : s.name, onChange: function (e) { return handleChange(e.target.value, s); } }))))));
        })))));
};
