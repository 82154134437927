"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Detail = exports.Carousel = void 0;
var react_1 = __importStar(require("react"));
var Context_1 = require("./Context");
var baseUrl = "https://storage.googleapis.com/icstorage/";
exports.Carousel = function (item, idx) { return (react_1.default.createElement("div", { key: idx, className: "carousel-item " + (idx === 0 ? "active" : "") },
    react_1.default.createElement("img", { src: "" + baseUrl + (item === null || item === void 0 ? void 0 : item.url), className: "d-block w-100", alt: "..." }))); };
exports.Detail = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var _k = window.__initialState, page = _k.page, customer = _k.customer;
    var context = react_1.useContext(Context_1.CategoryContext);
    var data = context.data, isLoading = context.isLoading, attributes = context.attributes, selectedAttribute = context.selectedAttribute, setSelectedAttribute = context.setSelectedAttribute;
    // console.log('context', context);
    // console.log('isLoading', isLoading);
    if (isLoading) {
        return (react_1.default.createElement("div", { className: "d-flex justify-content-center" },
            react_1.default.createElement("div", { className: "spinner-border", role: "status" },
                react_1.default.createElement("span", { className: "sr-only" }, "Loading..."))));
    }
    var foundAttributes = attributes === null || attributes === void 0 ? void 0 : attributes.find(function (attr) { return attr._id === selectedAttribute; });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "border p-3 p-md-5 my-3 my-md-0" },
                react_1.default.createElement("div", { className: "row" },
                    react_1.default.createElement("div", { className: "col-12 col-md" },
                        react_1.default.createElement("div", { className: "text-center filter-result" },
                            react_1.default.createElement("div", { id: "carousel-cat", className: "carousel slide", "data-ride": "carousel" },
                                react_1.default.createElement("div", { className: "carousel-inner" }, (_b = (_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.map(function (img, index) { return (react_1.default.createElement("div", { key: index, className: "carousel-item " + (index === 0 ? "active" : "") },
                                    react_1.default.createElement("img", { src: "" + baseUrl + (img === null || img === void 0 ? void 0 : img.url), className: "d-block w-100", alt: "..." }))); })),
                                ((_d = (_c = data === null || data === void 0 ? void 0 : data.category) === null || _c === void 0 ? void 0 : _c.images) === null || _d === void 0 ? void 0 : _d.length) > 1 && (react_1.default.createElement(react_1.default.Fragment, null,
                                    react_1.default.createElement("button", { className: "carousel-control-prev", type: "button", "data-target": "#carousel-cat", "data-slide": "prev" },
                                        react_1.default.createElement("span", { className: "carousel-control-prev-icon", "aria-hidden": "true" }),
                                        react_1.default.createElement("span", { className: "sr-only" }, "Previous")),
                                    react_1.default.createElement("button", { className: "carousel-control-next", type: "button", "data-target": "#carousel-cat", "data-slide": "next" },
                                        react_1.default.createElement("span", { className: "carousel-control-next-icon", "aria-hidden": "true" }),
                                        react_1.default.createElement("span", { className: "sr-only" }, "Next"))))))),
                    react_1.default.createElement("div", { className: "col-12 col-md" },
                        react_1.default.createElement("h2", { className: "h1" }, (_f = (_e = page === null || page === void 0 ? void 0 : page.category) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : ""),
                        react_1.default.createElement("p", { dangerouslySetInnerHTML: {
                                __html: (_h = (_g = data === null || data === void 0 ? void 0 : data.category) === null || _g === void 0 ? void 0 : _g.shortDescription) !== null && _h !== void 0 ? _h : ""
                            } }),
                        react_1.default.createElement("div", { className: "text-center text-md-left" },
                            react_1.default.createElement("a", { href: "/product" + (page === null || page === void 0 ? void 0 : page.url), className: "btn btn-violet", style: { marginRight: 4 } }, "Start Creating"),
                            (customer === null || customer === void 0 ? void 0 : customer._id) && (react_1.default.createElement("a", { href: "https://storage.googleapis.com/ic-printribe-editor/downloadable-pricelists/PT_Pricelist.pdf", className: "btn btn-violet btn-outline-primary" }, "Download Pricing")))))),
            react_1.default.createElement("div", { className: "filter" },
                react_1.default.createElement("div", { className: "row" },
                    react_1.default.createElement("div", { className: "col-12 col-md-8 offset-md-2" },
                        react_1.default.createElement("div", { className: "d-flex flex-wrap justify-content-center p-md-5" }, attributes === null || attributes === void 0 ? void 0 : attributes.map(function (g) {
                            var _a, _b;
                            return (react_1.default.createElement("button", { key: g, type: "button", onClick: function () { return setSelectedAttribute(g._id); }, className: "btn btn-outline-primary m-2 " + (selectedAttribute === g._id ? "active-pink" : "") }, (_b = (_a = g.type) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.name));
                        }))))),
            react_1.default.createElement("hr", null),
            react_1.default.createElement("div", { className: "filter-result" },
                react_1.default.createElement("div", { className: "row" }, (_j = foundAttributes === null || foundAttributes === void 0 ? void 0 : foundAttributes.attributes) === null || _j === void 0 ? void 0 : _j.map(function (attr, index) {
                    var _a, _b, _c;
                    return (react_1.default.createElement("div", { key: index, className: "col-12 col-md-4 mb-3" },
                        react_1.default.createElement("div", { className: "mb-3 text-center" }, ((_a = attr === null || attr === void 0 ? void 0 : attr.imagesSecondary) === null || _a === void 0 ? void 0 : _a.length) > 1 ? (react_1.default.createElement("div", { key: index, id: "carousel-" + index, className: "carousel slide mx-auto", "data-ride": "carousel", style: { maxWidth: "280px" } },
                            react_1.default.createElement("div", { className: "carousel-inner" }, attr.imagesSecondary.map(exports.Carousel)),
                            react_1.default.createElement("button", { className: "carousel-control-prev", type: "button", "data-target": "#carousel-" + index, "data-slide": "prev" },
                                react_1.default.createElement("span", { className: "carousel-control-prev-icon", "aria-hidden": "true" }),
                                react_1.default.createElement("span", { className: "sr-only" }, "Previous")),
                            react_1.default.createElement("button", { className: "carousel-control-next", type: "button", "data-target": "#carousel-" + index, "data-slide": "next" },
                                react_1.default.createElement("span", { className: "carousel-control-next-icon", "aria-hidden": "true" }),
                                react_1.default.createElement("span", { className: "sr-only" }, "Next")))) : (react_1.default.createElement("img", { src: "" + baseUrl + ((_c = (_b = attr === null || attr === void 0 ? void 0 : attr.imagesSecondary) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.url), alt: "", style: { maxWidth: "200px" }, className: "mx-auto" }))),
                        react_1.default.createElement("div", { className: "mx-auto", style: { maxWidth: "75%" } },
                            react_1.default.createElement("p", { className: "mb-0 text-center" },
                                react_1.default.createElement("strong", null, attr === null || attr === void 0 ? void 0 : attr.name)), attr === null || attr === void 0 ? void 0 :
                            attr.description)));
                }))))));
};
