"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayFinder = exports.percentDiff = exports.positionAbsolute = exports.positionRelative = exports.downloadBlob = exports.dataURItoBlob = exports.bestFit = exports.useDebounce = exports.customDropdown = void 0;
var react_1 = require("react");
exports.customDropdown = function () { };
function useDebounce(value, delay) {
    // State and setters for debounced value
    var _a = react_1.useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    react_1.useEffect(function () {
        // Set debouncedValue to value (passed in) after the specified delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Return a cleanup function that will be called every time ...
        // ... useEffect is re-called. useEffect will only be re-called ...
        // ... if value changes (see the inputs array below).
        // This is how we prevent debouncedValue from changing if value is ...
        // ... changed within the delay period. Timeout gets cleared and restarted.
        // To put it in context, if the user is typing within our app's ...
        // ... search box, we don't want the debouncedValue to update until ...
        // ... they've stopped typing for more than 500ms.
        return function () {
            clearTimeout(handler);
        };
    }, 
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]);
    return debouncedValue;
}
exports.useDebounce = useDebounce;
function bestFit(container, item) {
    var orientation = "square";
    if (item.width > item.height) {
        orientation = "landscape";
    }
    else if (item.width < item.height) {
        orientation = "portrait";
    }
    var scale = 1;
    var scaledDims = __assign({}, item);
    var widthRatio = item.width / container.width;
    var heightRatio = item.height / container.height;
    if (widthRatio > heightRatio) {
        scaledDims.width = Math.round(item.width / widthRatio);
        scaledDims.height = Math.round(item.height / widthRatio);
        scale = widthRatio;
    }
    else {
        scaledDims.width = Math.round(item.width / heightRatio);
        scaledDims.height = Math.round(item.height / heightRatio);
        scale = heightRatio;
    }
    return __assign(__assign({}, scaledDims), { scale: scale });
}
exports.bestFit = bestFit;
function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}
exports.dataURItoBlob = dataURItoBlob;
function downloadBlob(blob, name) {
    if (name === void 0) { name = "download.jpg"; }
    var blobUrl = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
    }));
    document.body.removeChild(link);
}
exports.downloadBlob = downloadBlob;
exports.positionRelative = function (coor, containerDims, newContainerDims) {
    var x = newContainerDims.width * (coor.x / containerDims.width);
    var y = newContainerDims.height * (coor.y / containerDims.height);
    return {
        x: x,
        y: y
    };
};
exports.positionAbsolute = function (artworkCoor, previewArtworkCoor, canvasCoor) {
    var x, y;
    if (artworkCoor.x === previewArtworkCoor.x) {
        x = canvasCoor.x;
    }
    else {
        var diff = artworkCoor.x - canvasCoor.x;
        x = previewArtworkCoor.x + diff;
    }
    if (artworkCoor.y === previewArtworkCoor.y) {
        y = canvasCoor.y;
    }
    else {
        var diff = artworkCoor.y - canvasCoor.y;
        y = previewArtworkCoor.y + diff;
    }
    return {
        x: x,
        y: y
    };
};
exports.percentDiff = function (a, b) {
    var percentage = 0;
    percentage = 100 * (Math.abs(a - b) / ((a + b) / 2));
    return percentage;
};
exports.arrayFinder = function (arr, field, key) { return arr === null || arr === void 0 ? void 0 : arr.find(function (a) { return a[field] === key; }); };
