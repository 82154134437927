"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.bestFitArtwork = exports.bestFit = exports.checkResolution = exports.rotatingPoint = exports.rotationDims = exports.dataURItoBlob = exports.downloadURI = exports.simpleRound = void 0;
exports.simpleRound = function (v) { return Math.ceil(v * 100) / 100; };
function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // link = null;
}
exports.downloadURI = downloadURI;
function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}
exports.dataURItoBlob = dataURItoBlob;
function rotationDims(dims, rotation) {
    if (rotation % 180 === 0) {
        return dims;
    }
    return { width: dims === null || dims === void 0 ? void 0 : dims.height, height: dims === null || dims === void 0 ? void 0 : dims.width };
}
exports.rotationDims = rotationDims;
exports.rotatingPoint = function (_a, deg) {
    var x1 = _a.x1, y1 = _a.y1;
    var degToRad = Math.PI / 180;
    var rcos = Math.cos(deg * degToRad);
    var rsin = Math.sin(deg * degToRad);
    var x = x1 * rcos - y1 * rsin;
    var y = y1 * rcos + x1 * rsin;
    // console.log('y', y);
    // console.log('x', x);
    return {
        x: x,
        y: y
    };
};
function checkResolution(productDims, shapeDims, artworkDims, rotation) {
    var _a, _b, _c, _d;
    var rotatedProductDims = rotationDims(productDims, rotation);
    var rotatedShapeDims = rotationDims(shapeDims, rotation);
    var minDPI = 150;
    // Assume actual shape dims is * 150
    // console.log("scaled ", artworkDims.width * (artworkDims.scaleX ?? 1));
    var prodWidthInches = productDims.width / 2.54;
    var prodHeightInches = productDims.height / 2.54;
    var relativeDims = {
        width: ((artworkDims.width * ((_a = artworkDims.scaleX) !== null && _a !== void 0 ? _a : 1)) / shapeDims.width) *
            prodWidthInches,
        height: ((artworkDims.height * ((_b = artworkDims.scaleY) !== null && _b !== void 0 ? _b : 1)) / shapeDims.height) *
            prodHeightInches
    };
    // const scaleX = artworkDims?.scaleX ?? 1,
    //   scaleY = artworkDims?.scaleY ?? 1;
    // const scale = scaleX > scaleY ? scaleX * 100 : scaleY * 100;
    // const pixels = artworkDims.width * artworkDims.height * (scale / 100);
    // const wRatio = artworkDims.height / artworkDims.width;
    // const hRatio = artworkDims.width / artworkDims.height;
    // const wScaled = Math.round(Math.sqrt(pixels * wRatio));
    // const hScaled = Math.round(Math.sqrt(pixels * hRatio));
    // const wInches = wScaled / 96;
    // const hInches = hScaled / 96;
    // console.log("wInches", wInches, "hInches", hInches);
    // console.log(
    //   "prodWidthInches",
    //   prodWidthInches,
    //   "prodHeightInches",
    //   prodHeightInches
    // );
    var hasWarning = false;
    var actualDPI = {
        width: artworkDims.width / relativeDims.width,
        height: artworkDims.height / relativeDims.height
    };
    if (actualDPI.width < minDPI || actualDPI.height < minDPI) {
        hasWarning = true;
    }
    // Todo: check the absolute DPI
    // const actualDPI = {
    //   width: Math.round(wOriginalInches >= prodWidthInches ? 150 : 72),
    //   height: Math.round(hOriginalInches >= prodHeightInches ? 150 : 72)
    // };
    // const actualDPI = {
    //   width: pxTocm(artworkDims.width) * 0.393701,
    //   height: pxTocm(artworkDims.height) * 0.393701
    // };
    // console.log("actualDPI", actualDPI);
    // if (actualDPI.width < minDPI || actualDPI.height < minDPI) {
    //   hasWarning = true;
    // }
    // console.log("Check Resolution", {
    //   productDims,
    //   shapeDims,
    //   artworkDims,
    //   rotatedProductDims,
    //   rotation,
    //   relativeDimsInches,
    //   actualDPI
    // });
    /*
    const minResolution = 150;
    let hasWarning = false;
    const detectedResolutions = {
      width: artworkDims.width / productDims.width,
      height: artworkDims.height / productDims.height,
    };
  
  
    const imageWidthStretch = artworkDims.scaleX > 1
    const imageHeightStretch = artworkDims.scaleY > 1
  
    if (artworkDims.width && productDims.width && imageWidthStretch || artworkDims.width < (productDims.width * 120)) {
      hasWarning = true;
    }
  
    if (artworkDims.height && productDims.height && imageHeightStretch || artworkDims.height < (productDims.height * 120)) {
      hasWarning = true;
    }
    */
    return {
        shapeDims: shapeDims,
        productDims: productDims,
        rotatedProductDims: rotatedProductDims,
        hasResolutionWarning: hasWarning,
        minDPI: minDPI,
        scaledArtworkSize: {
            width: artworkDims.width * ((_c = artworkDims.scaleX) !== null && _c !== void 0 ? _c : 1),
            height: artworkDims.height * ((_d = artworkDims.scaleY) !== null && _d !== void 0 ? _d : 1)
        },
        relativeDims: relativeDims,
        actualDPI: actualDPI
    };
}
exports.checkResolution = checkResolution;
function bestFit(container, item) {
    var orientation = "square";
    if (item.width > item.height) {
        orientation = "landscape";
    }
    else if (item.width < item.height) {
        orientation = "portrait";
    }
    var scale = 1;
    var scaledDims = __assign({}, item);
    var widthRatio = item.width / container.width;
    var heightRatio = item.height / container.height;
    if (widthRatio > heightRatio) {
        scaledDims.width = Math.round(item.width / widthRatio);
        scaledDims.height = Math.round(item.height / widthRatio);
        scale = widthRatio;
    }
    else {
        scaledDims.width = Math.round(item.width / heightRatio);
        scaledDims.height = Math.round(item.height / heightRatio);
        scale = heightRatio;
    }
    return __assign(__assign({}, scaledDims), { scale: scale });
}
exports.bestFit = bestFit;
function bestFitArtwork(container, item) {
    var orientation = "square";
    if (item.width > item.height) {
        orientation = "landscape";
    }
    else if (item.width < item.height) {
        orientation = "portrait";
    }
    var scale = 1;
    var scaledDims = __assign({}, item);
    // console.log("item", item, "container", container);
    var widthRatio = item.width / container.width;
    var heightRatio = item.height / container.height;
    // const r = Math.min(widthRatio, heightRatio);
    // console.log(
    //   "widthRation",
    //   widthRatio,
    //   "heightRation",
    //   heightRatio,
    //   "before if",
    //   r
    // );
    if (widthRatio < heightRatio) {
        // scaledDims.width = Math.round(item.width / r);
        // scaledDims.height = Math.round(item.height / r);
        scale = heightRatio;
    }
    else {
        // scaledDims.width = Math.round(item.width / r);
        // scaledDims.height = Math.round(item.height / r);
        scale = widthRatio;
    }
    // console.log("scale top else", r);
    var scaledWidth = item.width * Number((1 / scale).toFixed(2));
    var scaledHeight = item.height * Number((1 / scale).toFixed(2));
    // console.log("scaledWidth", scaledWidth, "scaledHeight", scaledHeight);
    if (scaledWidth < container.width || scaledHeight < container.height) {
        scale = Math.floor(scale) || 0;
    }
    // console.log(
    //   "helper scale",
    //   scale,
    //   "widthRatio",
    //   widthRatio,
    //   "heightRatio",
    //   heightRatio
    // );
    return __assign(__assign({}, scaledDims), { scale: scale });
}
exports.bestFitArtwork = bestFitArtwork;
