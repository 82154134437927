"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScalePanel = void 0;
var React = __importStar(require("react"));
var Context_1 = require("../Context");
var useState = React.useState;
function ScalePanel(props) {
    // const [scale, setScale] = useState(100)
    var ctx = React.useContext(Context_1.GlobalContext);
    var scalePercent = ctx.scalePercent, handleScaling = ctx.handleScaling, 
    // handleTransform,
    handleRotation = ctx.handleRotation, isArtworkSelected = ctx.isArtworkSelected, artworkRotation = ctx.artworkRotation, dpi = ctx.dpi
    // imageTransform,
    ;
    // console.log("dpi", dpi);
    var textColor = isArtworkSelected ? "txc-dark-blue" : "text-muted";
    var isClickable = isArtworkSelected ? { cursor: "pointer" } : null;
    // const isActive = imageTransform
    return (React.createElement("div", { className: "d-flex align-items-center" },
        React.createElement("div", { className: "d-flex flex-column align-self-stretch" },
            React.createElement("div", { className: "" },
                React.createElement("p", { className: "mb-1" },
                    React.createElement("small", null, "Rotate")),
                React.createElement("div", { style: { marginTop: ".25rem" } },
                    React.createElement("button", { className: "btn btn-transparent px-1 ", onClick: function () {
                            return handleRotation(artworkRotation === -360 ? -90 : artworkRotation - 90);
                        } },
                        React.createElement("i", { className: "fas fa-undo" })),
                    React.createElement("button", { className: "btn btn-transparent px-1", onClick: function () {
                            return handleRotation(artworkRotation === 360 ? 90 : artworkRotation + 90);
                        } },
                        React.createElement("i", { className: "fas fa-redo" }))))),
        React.createElement("div", { className: "border-left  mx-2 pl-2" },
            React.createElement("p", { className: "mb-1" },
                React.createElement("small", null, "Image Scale")),
            React.createElement("div", { className: "ic-custom-range mb-2" },
                React.createElement("button", { className: "btn", disabled: scalePercent === 0, onClick: function () { return scalePercent > 1 && handleScaling(scalePercent - 1); } }, "-"),
                React.createElement("div", { className: "input-container" },
                    React.createElement("input", { type: "range", name: "image-scale", className: "input-range", min: "1", max: "100", value: scalePercent, step: "1", onChange: function (e) { return handleScaling(Number(e.target.value)); } })),
                React.createElement("button", { className: "btn", disabled: scalePercent === 100, onClick: function () {
                        return scalePercent < 100 && handleScaling(scalePercent + 1);
                    } }, "+"))),
        React.createElement("div", { className: "d-flex align-self-stretch pr-2 align-items-center border-right" },
            React.createElement("div", { style: { marginTop: "0.75rem" } },
                React.createElement("span", { className: "scale border bg-white px-1 small mx-1" },
                    scalePercent,
                    " %"),
                React.createElement("span", { className: "dpi border bg-white px-1 small mx-1" },
                    Math.round(dpi),
                    "dpi")),
            Math.round(dpi) < 150 && (React.createElement("p", { className: "text-danger small mt-2  ml-2 mb-0" }, "Low Image resolution"))),
        React.createElement("hr", null)));
}
exports.ScalePanel = ScalePanel;
