"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var useState = React.useState, useContext = React.useContext;
var Quantity = function (props) {
    var _a = props.isDisabled, isDisabled = _a === void 0 ? false : _a;
    var context = useContext(Context_1.ProductContext);
    var quantity = context.quantity, setQuantity = context.setQuantity;
    var handleChange = function (e) {
        var value = e.target.value;
        var newValue = value.replace(/\D+/g, "");
        setQuantity(newValue);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "custom custom-form-number" },
            React.createElement("button", { type: "button", className: "btn btn-primary-alt decrease", disabled: isDisabled, onClick: function () {
                    return quantity > 1 ? setQuantity(Number(quantity) - 1) : null;
                } },
                " ",
                "-",
                " "),
            React.createElement("input", { type: "text", disabled: isDisabled, className: "form-control", value: quantity, onChange: handleChange }),
            React.createElement("button", { type: "button", className: "btn btn-primary-alt increase", disabled: isDisabled, onClick: function () { return setQuantity(Number(quantity) + 1); } }, "+"))));
};
exports.default = Quantity;
