"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionButtons = exports.countCopy = void 0;
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var remote_1 = require("../../../remote");
var Button_1 = require("../../common/Button");
exports.countCopy = function (name, items) {
    var copy = items === null || items === void 0 ? void 0 : items.reduce(function (acc, cur) {
        var isACopy = name === null || name === void 0 ? void 0 : name.search("Copy");
        var newName = name.slice(0, isACopy);
        var found = cur.name.search(newName);
        if (found >= 0) {
            acc.push(cur);
        }
        return acc;
    }, []);
    var highest = Math.max.apply(Math, copy.map(function (c) {
        var _a;
        var isACopy = (_a = c.name) === null || _a === void 0 ? void 0 : _a.search("Copy");
        if (isACopy >= 0) {
            var hasANumber = parseInt(c.name.slice(isACopy + 4, c.name.length));
            if (hasANumber) {
                var incrementNumber = hasANumber + 1;
                return incrementNumber;
            }
            else {
                return 2;
            }
        }
        else {
            return 1;
        }
    }));
    if (highest === 1) {
        return name + " Copy";
    }
    else {
        var isACopy = name === null || name === void 0 ? void 0 : name.search("Copy");
        var newName = name.slice(0, isACopy);
        return newName.trim() + " Copy " + highest;
    }
};
var useContext = React.useContext, useState = React.useState;
exports.ActionButtons = function (props) {
    var _a = props.selectedCollections, selectedCollections = _a === void 0 ? [] : _a;
    var ctx = useContext(Context_1.ProductContext);
    var isSubmitting = ctx.isSubmitting, selected = ctx.selected, setIsReload = ctx.setIsReload, handleToggleModal = ctx.handleToggleModal, handleDelete = ctx.handleDelete;
    var _b = useState({
        duplicate: false
    }), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var handleDuplicate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, all, e_1, errMsg;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(__assign(__assign({}, isLoading), { duplicate: true }));
                    setErrorMessage("");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    res = selected === null || selected === void 0 ? void 0 : selected.map(function (s) { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, remote_1.addProductLibrary({
                                        product: (_a = s === null || s === void 0 ? void 0 : s.product) === null || _a === void 0 ? void 0 : _a._id,
                                        // customerArtwork: s?.customerArtwork?._id,
                                        name: exports.countCopy(s === null || s === void 0 ? void 0 : s.name, ctx.items)
                                    })];
                                case 1: return [2 /*return*/, _b.sent()];
                            }
                        });
                    }); });
                    return [4 /*yield*/, Promise.all(res)];
                case 2:
                    all = _a.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { product: true })); });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    errMsg = (e_1 === null || e_1 === void 0 ? void 0 : e_1.code) < 500
                        ? e_1.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(errMsg);
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(__assign(__assign({}, isLoading), { duplicate: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", { className: "font-weight-bold" }, "What would you like to do with the selected?"),
        React.createElement("br", null),
        errorMessage && React.createElement("div", { className: "alert alert-danger" }, errorMessage),
        React.createElement("div", null,
            React.createElement(Button_1.Button, { disabled: (selected === null || selected === void 0 ? void 0 : selected.length) > 1, content: "ADD TO CART", className: "mx-1", onClick: function () { return handleToggleModal("add"); } }),
            React.createElement(Button_1.Button, { content: "EDIT PRODUCT", className: "mx-1", onClick: function () { return handleToggleModal("edit"); } }),
            React.createElement(Button_1.Button, { disabled: isLoading.duplicate, loading: isLoading.duplicate, content: "DUPLICATE", className: "mx-1", onClick: handleDuplicate }),
            React.createElement(Button_1.Button, { disabled: isSubmitting.delete, loading: isSubmitting.delete, content: "DELETE", className: "mx-1", onClick: handleDelete }))));
};
