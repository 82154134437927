"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToCart = void 0;
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var remote_1 = require("../../../remote");
var common_1 = require("../../common");
var useContext = React.useContext, useState = React.useState;
var isBrowser = function () { return typeof window !== "undefined"; };
exports.AddToCart = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = window.__initialState, _h = _g.page, _j = _h.product, product = _j === void 0 ? {} : _j, _k = _h.category, category = _k === void 0 ? {} : _k, _l = _g.tenant, tenant = _l === void 0 ? {} : _l;
    var ctx = useContext(Context_1.ProductContext);
    var selected = ctx.selected, isModalOpen = ctx.isModalOpen, setIsReload = ctx.setIsReload, handleToggleModal = ctx.handleToggleModal;
    var _m = useState(1), quantity = _m[0], setQuantity = _m[1];
    var _o = useState(""), errorMessage = _o[0], setErrorMessage = _o[1];
    var _p = useState({
        product: false
    }), isSubmitting = _p[0], setIsSubmitting = _p[1];
    // const handleChange = (quantity: number, product: any) => setProducts({
    //   ...products,
    //   [product._id]: {
    //     ...product,
    //     quantity
    //   }
    // })
    var firstSelected = selected[0];
    var handleAddToCart = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1, errMsg;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { product: true }));
                    if (isBrowser() && (window === null || window === void 0 ? void 0 : window.gtag) !== undefined) {
                        window === null || window === void 0 ? void 0 : window.gtag("event", "add_to_cart", {
                            currency: tenant.currency,
                            value: (_a = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.product) === null || _a === void 0 ? void 0 : _a.price,
                            items: [
                                {
                                    item_id: (_b = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.product) === null || _b === void 0 ? void 0 : _b.key,
                                    item_name: (_d = (_c = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.product) === null || _c === void 0 ? void 0 : _c.staticName) !== null && _d !== void 0 ? _d : "",
                                    item_category: category === null || category === void 0 ? void 0 : category.name,
                                    price: (_e = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.product) === null || _e === void 0 ? void 0 : _e.price
                                }
                            ]
                        });
                    }
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.addToCart({
                            product: firstSelected.product._id,
                            quantity: quantity
                        })];
                case 2:
                    res = _f.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _f.sent();
                    errMsg = (e_1 === null || e_1 === void 0 ? void 0 : e_1.code) < 500
                        ? e_1.message
                        : "We are experiencing technical difficulties. Please try again";
                    setErrorMessage(errMsg);
                    return [3 /*break*/, 4];
                case 4:
                    setIsSubmitting(__assign(__assign({}, isSubmitting), { product: false }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null, isModalOpen.add && (React.createElement(common_1.ModalWrapper, { isOpen: true, onClose: function () {
            handleToggleModal("add");
            setIsSubmitting({
                product: false
            });
        }, style: { maxWidth: "500px" } },
        React.createElement("h3", { className: "text-center" }, "Add to Cart"),
        React.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 col-md-4" },
                    React.createElement("img", { src: (_c = (_b = (_a = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.product) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.url, alt: (_d = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.name) !== null && _d !== void 0 ? _d : "" })),
                React.createElement("div", { className: "col-12 col-md" },
                    React.createElement("p", null,
                        React.createElement("strong", null, (_e = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.name) !== null && _e !== void 0 ? _e : "")),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("input", { type: "text", className: "form-control custom-form-control", defaultValue: (_f = firstSelected === null || firstSelected === void 0 ? void 0 : firstSelected.quantity) !== null && _f !== void 0 ? _f : 1, onChange: function (e) { return setQuantity(Number(e.target.value)); } })))),
            errorMessage && (React.createElement("div", { className: "alert alert-danger" }, errorMessage)),
            React.createElement("div", { className: "text-center" },
                React.createElement(common_1.Button, { disabled: isSubmitting.product, loading: isSubmitting.product, onClick: handleAddToCart, content: "Add to Cart" })))))));
};
