"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Context_1 = require("../Context");
var common_1 = require("../../common");
// const formatOptionLabel = ({ label, price }) => (
//   <div className="ic-custom-option-container">
//     <div>{label}</div>
//     <span className='ic-custom-option-price'>
//       <NumberFormat
//         value={price || 0}
//         displayType={'text'}
//         thousandSeparator={true}
//         prefix={'$'}
//         decimalScale={2}
//         fixedDecimalScale={true}
//       />
//     </span>
//   </div>
// )
var useState = React.useState, useEffect = React.useEffect, useContext = React.useContext;
var baseUrl = "https://storage.googleapis.com/icstorage/";
var tooltip = (React.createElement("div", { className: "ic-tooltip ml-2" },
    React.createElement("span", { className: "ic-tooltip-trigger rounded-circle d-inline-flex align-items-center justify-content-center" }, "?"),
    React.createElement("span", { className: "ic-tooltip-text" },
        "Measurements refer to the internal artwork/media size. Please use your selected moulding dimensions below to determine the overall finished artwork size.",
        " ")));
exports.default = (function (props) {
    var _a, _b, _c;
    var _d = props.isDisabled, isDisabled = _d === void 0 ? false : _d;
    var context = useContext(Context_1.ProductContext);
    var attributes = context.attributes, selectedAttributes = context.selectedAttributes, handleAttributesChange = context.handleAttributesChange, productData = context.productData, selectedStandard = context.selectedStandard, handleSelectStandard = context.handleSelectStandard, selectedSpecialty = context.selectedSpecialty, handleSelectSpecialty = context.handleSelectSpecialty, isChanging = context.isChanging;
    if (!selectedAttributes) {
        return null;
    }
    // console.log("isChanging", isChanging);
    // console.log('attributes', attributes);
    // console.log('selectedAttributes', selectedAttributes);
    var MOULDING = attributes.MOULDING, ACRYLIC = attributes.ACRYLIC, MOUNTING = attributes.MOUNTING;
    var frameGroup1 = ["ORIENTATION", "SIZE", "PAPER", "COLOR"];
    // const frameGroup2 = ["ACRYLIC"]
    var foundMoulding = MOULDING === null || MOULDING === void 0 ? void 0 : MOULDING.find(function (a) { return a._id === selectedAttributes["MOULDING"]; });
    var foundAcrylic = ACRYLIC === null || ACRYLIC === void 0 ? void 0 : ACRYLIC.find(function (a) { return a._id === selectedAttributes["ACRYLIC"]; });
    // console.log('MOULDING', MOULDING);
    var hasMoreAttributes = (MOUNTING === null || MOUNTING === void 0 ? void 0 : MOUNTING.length) && (MOULDING === null || MOULDING === void 0 ? void 0 : MOULDING.length) && (ACRYLIC === null || ACRYLIC === void 0 ? void 0 : ACRYLIC.length);
    return (React.createElement("div", { className: "position-relative" },
        isChanging && (React.createElement("div", { className: "position-absolute d-flex align-items-center justify-content-center", style: {
                zIndex: 100,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255,255,255,0.5)"
            } },
            React.createElement("p", { className: "mb-0 txc-pink" }, "Changing..."))), frameGroup1 === null || frameGroup1 === void 0 ? void 0 :
        frameGroup1.map(function (f, index) {
            var _a, _b, _c, _d;
            var foundAttr = (_a = attributes === null || attributes === void 0 ? void 0 : attributes[f]) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a._id === selectedAttributes[f]; });
            return (((_b = attributes === null || attributes === void 0 ? void 0 : attributes[f]) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement("div", { key: index, className: "row align-items-center" },
                React.createElement("div", { className: "col-12 col-md-5" },
                    React.createElement("strong", null, f),
                    " ",
                    f === "SIZE" ? tooltip : null),
                React.createElement("div", { className: "col-12 col-md" },
                    React.createElement("div", { className: "form-group" },
                        React.createElement(common_1.CustomSelect, { defaultValue: {
                                label: foundAttr === null || foundAttr === void 0 ? void 0 : foundAttr.name,
                                value: foundAttr === null || foundAttr === void 0 ? void 0 : foundAttr._id
                            }, isDisabled: isChanging || isDisabled, options: (_c = attributes === null || attributes === void 0 ? void 0 : attributes[f]) === null || _c === void 0 ? void 0 : _c.map(function (m) { return ({
                                label: m.name,
                                value: m._id
                            }); }), onChange: function (data) {
                                var _a;
                                return handleAttributesChange(__assign(__assign({}, selectedAttributes), (_a = {}, _a[f] = data.value, _a)));
                            }, value: (_d = attributes === null || attributes === void 0 ? void 0 : attributes[f]) === null || _d === void 0 ? void 0 : _d.map(function (m) { return ({ label: m.name, value: m._id }); }).find(function (a) { return a.value === selectedAttributes[f]; }) }))))));
        }),
        hasMoreAttributes && (React.createElement(React.Fragment, null,
            React.createElement("hr", null),
            React.createElement("br", null))),
        (MOULDING === null || MOULDING === void 0 ? void 0 : MOULDING.length) && (React.createElement(React.Fragment, null,
            React.createElement("h6", null, "FRAME MOULDING"), MOULDING === null || MOULDING === void 0 ? void 0 :
            MOULDING.map(function (s) {
                var _a, _b;
                return (React.createElement("div", { key: s._id, className: "ic-custom-radio--colored mx-1 " + ((foundMoulding === null || foundMoulding === void 0 ? void 0 : foundMoulding._id) === s._id ? "active" : ""), onClick: function () {
                        var _a;
                        isChanging || isDisabled
                            ? null
                            : handleAttributesChange(__assign(__assign({}, selectedAttributes), (_a = {}, _a["MOULDING"] = s._id, _a)));
                    } },
                    React.createElement("img", { src: "" + baseUrl + ((_b = (_a = s === null || s === void 0 ? void 0 : s.images) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url), className: "rounded-circle", alt: "" })));
            }),
            React.createElement("div", { className: "ic-custom-tooltip mb-3", dangerouslySetInnerHTML: {
                    __html: "<strong>" + ((_a = foundMoulding === null || foundMoulding === void 0 ? void 0 : foundMoulding.name) !== null && _a !== void 0 ? _a : "") + " - </strong> " + ((_b = foundMoulding === null || foundMoulding === void 0 ? void 0 : foundMoulding.shortDescription) !== null && _b !== void 0 ? _b : "")
                } }))),
        (ACRYLIC === null || ACRYLIC === void 0 ? void 0 : ACRYLIC.length) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row align-items-center" },
                React.createElement("div", { className: "col-12 col-md-5" },
                    React.createElement("strong", null, "ACRYLIC")),
                React.createElement("div", { className: "col-12 col-md" },
                    React.createElement("div", { className: "form-group" },
                        React.createElement(common_1.CustomSelect, { defaultValue: {
                                label: foundAcrylic === null || foundAcrylic === void 0 ? void 0 : foundAcrylic.name,
                                value: foundAcrylic === null || foundAcrylic === void 0 ? void 0 : foundAcrylic._id
                            }, isDisabled: isChanging || isDisabled, options: ACRYLIC === null || ACRYLIC === void 0 ? void 0 : ACRYLIC.map(function (m) { return ({ label: m.name, value: m._id }); }), onChange: function (data) {
                                var _a;
                                return handleAttributesChange(__assign(__assign({}, selectedAttributes), (_a = {}, _a["ACRYLIC"] = data.value, _a)));
                            } })))),
            React.createElement("p", null, (_c = foundAcrylic === null || foundAcrylic === void 0 ? void 0 : foundAcrylic.shortDescription) !== null && _c !== void 0 ? _c : ""))),
        (MOUNTING === null || MOUNTING === void 0 ? void 0 : MOUNTING.length) && (React.createElement(React.Fragment, null,
            React.createElement("h6", null, "BORDERS + MATTING"), MOUNTING === null || MOUNTING === void 0 ? void 0 :
            MOUNTING.map(function (s, index) {
                var _a, _b;
                return (React.createElement("div", { key: s._id, className: "form-group" },
                    React.createElement("label", { className: "row align-items-center", onClick: function () {
                            var _a;
                            isChanging || isDisabled
                                ? null
                                : handleAttributesChange(__assign(__assign({}, selectedAttributes), (_a = {}, _a["MOUNTING"] = s._id, _a)));
                        } },
                        React.createElement("div", { className: "pl-3 flex-shrink-1" },
                            React.createElement("div", { className: "ic-custom-radio" },
                                React.createElement("input", { type: "radio", checked: selectedAttributes["MOUNTING"] === s._id, name: "border-matting", onChange: function () { }, className: "" }),
                                React.createElement("div", { className: "checkmark" }))),
                        React.createElement("div", { className: "col-12 col-md" },
                            React.createElement("div", { className: "" },
                                React.createElement("p", { className: "mb-0" }, (_a = s === null || s === void 0 ? void 0 : s.name) !== null && _a !== void 0 ? _a : "",
                                    " - ", (_b = s === null || s === void 0 ? void 0 : s.shortDescription) !== null && _b !== void 0 ? _b : ""))))));
            })))));
});
