"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_intl_1 = require("react-intl");
var Context_1 = require("./Context");
var remote_1 = require("./../../remote");
var Detail_1 = require("./Detail");
var Selector_1 = require("./Selector");
var useEffect = React.useEffect, useState = React.useState;
var getProductFromCache = function (products, selectedAttributes) {
    var _a;
    if (selectedAttributes)
        return (_a = Object === null || Object === void 0 ? void 0 : Object.keys(selectedAttributes)) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, cur) {
            return acc === null || acc === void 0 ? void 0 : acc.filter(function (product) { return product === null || product === void 0 ? void 0 : product.attributes.find(function (a) { return a.value === selectedAttributes[cur]; }); });
        }, products);
};
function filterBySelectedAttr(attr, selectedAttr) {
    var _a;
    return (_a = Object.keys(selectedAttr)) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, key) {
        var _a, _b, _c;
        var index = (_a = attr[key]) === null || _a === void 0 ? void 0 : _a.findIndex(function (a) { return !a.isOutOfStockManual; });
        acc[key] = (_c = (_b = attr[key]) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c._id;
        return acc;
    }, {});
}
var Product = function () {
    var _a, _b, _c;
    var _d = window.__initialState, _e = _d.page, _f = _e.product, product = _f === void 0 ? {} : _f, _g = _e.category, category = _g === void 0 ? {} : _g, _h = _d.tenant, tenant = _h === void 0 ? {} : _h;
    var _j = useState(null), selectedProduct = _j[0], setSelectedProduct = _j[1];
    var _k = useState(null), selectedArtwork = _k[0], setSelectedArtwork = _k[1];
    var _l = useState(null), selectedStandard = _l[0], setSelectedStandard = _l[1];
    var _m = useState(null), selectedSpecialty = _m[0], setSelectedSpecialty = _m[1];
    var _o = useState(""), productName = _o[0], setProductName = _o[1];
    var _p = useState(1), quantity = _p[0], setQuantity = _p[1];
    var _q = useState(null), productData = _q[0], setProductData = _q[1];
    var _r = useState(false), isLoading = _r[0], setIsLoading = _r[1];
    var _s = useState(false), isChanging = _s[0], setIsChanging = _s[1];
    var refHandleExport = React.useRef({});
    var _t = useState([]), products = _t[0], setProducts = _t[1];
    var _u = useState({}), attributes = _u[0], setAttributes = _u[1];
    var _v = useState({}), selectedAttributes = _v[0], setSelectedAttributes = _v[1];
    var _w = useState(false), hasResolutionWarning = _w[0], setHasResolutionWarning = _w[1];
    var _x = useState("scale"), artworkPanel = _x[0], setArtworkPanel = _x[1];
    var _y = useState(""), saveArtwork = _y[0], setSaveArtwork = _y[1];
    var _z = useState(""), saveArtworkThumb = _z[0], setSaveArtworkThumb = _z[1];
    var _0 = useState({
        artwork: null,
        thumb: null
    }), saveArtworkDetail = _0[0], setSaveArtworkDetail = _0[1];
    var _1 = useState(null), selectedCollection = _1[0], setSelectedCollection = _1[1];
    var _2 = useState({
        height: 0,
        rotation: 0,
        scaleX: 0,
        scaleY: 0,
        width: 0,
        x: 0,
        y: 0
    }), artworkProps = _2[0], setArtworkProps = _2[1];
    var _3 = useState(null), imageEditorCtx = _3[0], setImageEditorCtx = _3[1];
    var _4 = useState([]), coaFields = _4[0], setCoaFields = _4[1];
    var categoryAttributes = (_a = category === null || category === void 0 ? void 0 : category.entityAttributes) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, cur) {
        acc[cur.toLowerCase()] = [];
        return acc;
    }, {});
    // console.log('categoryAttributes', categoryAttributes);
    // console.log('category', category);
    var fallback = __assign({ categoryKey: category === null || category === void 0 ? void 0 : category.hrCode }, categoryAttributes);
    // console.log('product', product);
    var loadProducts = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res_1, filteredAttributes, filteredSelectedAttributes, error_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    console.log("loading products");
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.calc(payload)];
                case 2:
                    res_1 = _f.sent();
                    setProducts((_a = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _a === void 0 ? void 0 : _a.products);
                    filteredAttributes = (_c = Object.keys((_b = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _b === void 0 ? void 0 : _b.attributes)) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, key) {
                        var _a, _b;
                        acc[key] = (_b = (_a = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _a === void 0 ? void 0 : _a.attributes[key]) === null || _b === void 0 ? void 0 : _b.filter(function (a) { return !a.isOutOfStockManual; });
                        return acc;
                    }, {});
                    setAttributes(filteredAttributes);
                    filteredSelectedAttributes = filterBySelectedAttr((_d = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _d === void 0 ? void 0 : _d.attributes, (_e = res_1 === null || res_1 === void 0 ? void 0 : res_1.data) === null || _e === void 0 ? void 0 : _e.selectedAttributes);
                    setSelectedAttributes(filteredSelectedAttributes);
                    // console.log("selectedAttributes", res?.data);
                    console.log("done loading products");
                    return [2 /*return*/, res_1];
                case 3:
                    error_1 = _f.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var payload, res, filteredSelectedAttributes, res2, foundProduct;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        setIsLoading(true);
                        payload = {
                            category: category === null || category === void 0 ? void 0 : category._id
                        };
                        return [4 /*yield*/, loadProducts(payload)];
                    case 1:
                        res = _e.sent();
                        filteredSelectedAttributes = filterBySelectedAttr((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.attributes, (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.selectedAttributes);
                        payload.selectedAttributes = filteredSelectedAttributes;
                        return [4 /*yield*/, loadProducts(payload)];
                    case 2:
                        res2 = _e.sent();
                        foundProduct = getProductFromCache((_c = res2 === null || res2 === void 0 ? void 0 : res2.data) === null || _c === void 0 ? void 0 : _c.products, (_d = res2 === null || res2 === void 0 ? void 0 : res2.data) === null || _d === void 0 ? void 0 : _d.selectedAttributes);
                        // console.log({ filteredSelectedAttributes });
                        setSelectedProduct(foundProduct === null || foundProduct === void 0 ? void 0 : foundProduct[0]);
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    // console.log("selectedProduct", selectedProduct);
    var search = new URLSearchParams(location.search);
    var artworkId = search.get("artwork");
    useEffect(function () {
        var loadArtwork = function (id) { return __awaiter(void 0, void 0, void 0, function () {
            var res, e_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, remote_1.findArtworkLibrary(id)];
                    case 1:
                        res = _c.sent();
                        setSelectedArtwork(res === null || res === void 0 ? void 0 : res.data);
                        setSelectedCollection((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.collections) === null || _b === void 0 ? void 0 : _b.map(function (c) { return c === null || c === void 0 ? void 0 : c._id; }));
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _c.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        artworkId && loadArtwork(artworkId);
    }, [artworkId]);
    var handleSelectArtwork = function (v) { return function (e) {
        var _a;
        setSelectedArtwork(v);
        setSelectedCollection((_a = v === null || v === void 0 ? void 0 : v.collections) === null || _a === void 0 ? void 0 : _a.map(function (c) { return c === null || c === void 0 ? void 0 : c._id; }));
        setSaveArtworkDetail({
            artwork: null,
            thumb: null
        });
        setImageEditorCtx(null);
    }; };
    var handleSelectStandard = function (v) { return setSelectedStandard(v); };
    var handleSelectSpecialty = function (v) { return setSelectedSpecialty(v); };
    var handleAttributesChange = function (updatedAttributes) { return __awaiter(void 0, void 0, void 0, function () {
        var foundProduct, payload, res, cacheProducts, innerFoundProduct;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setSelectedAttributes(updatedAttributes);
                    setSaveArtworkDetail({
                        artwork: null,
                        thumb: null
                    });
                    foundProduct = getProductFromCache(products, updatedAttributes);
                    // console.log("handleAttributesChange foundProduct", foundProduct);
                    if (foundProduct === null || foundProduct === void 0 ? void 0 : foundProduct.length) {
                        setSelectedProduct(foundProduct[0]);
                    }
                    payload = {
                        category: category === null || category === void 0 ? void 0 : category._id,
                        selectedAttributes: updatedAttributes
                    };
                    setIsChanging(true);
                    return [4 /*yield*/, loadProducts(payload)];
                case 1:
                    res = _b.sent();
                    setSelectedAttributes(res.data.selectedAttributes);
                    cacheProducts = __spreadArrays(products, (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.products) || []));
                    setProducts(cacheProducts);
                    innerFoundProduct = getProductFromCache(cacheProducts, res.data.selectedAttributes);
                    // console.log('innerFoundProduct', innerFoundProduct);
                    setIsChanging(false);
                    if (!(innerFoundProduct === null || innerFoundProduct === void 0 ? void 0 : innerFoundProduct.length)) {
                        // load agin
                        console.log("err");
                        return [2 /*return*/];
                    }
                    setSelectedProduct(innerFoundProduct[0]);
                    return [2 /*return*/];
            }
        });
    }); };
    var _learnq = window._learnq || [];
    var item = {
        ProductName: (_b = selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.staticName) !== null && _b !== void 0 ? _b : "",
        ProductID: selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id,
        SKU: selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.key,
        Categories: [category === null || category === void 0 ? void 0 : category.hrCode],
        Price: (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.price) || 0
    };
    _learnq.push(["track", "Viewed Product", item]);
    var context = {
        productData: productData,
        selectedProduct: selectedProduct,
        isLoading: isLoading,
        isChanging: isChanging,
        productName: productName,
        setProductName: setProductName,
        attributes: attributes,
        saveArtworkDetail: saveArtworkDetail,
        setSaveArtworkDetail: setSaveArtworkDetail,
        selectedCollection: selectedCollection,
        setSelectedCollection: setSelectedCollection,
        saveArtwork: saveArtwork,
        setSaveArtwork: setSaveArtwork,
        saveArtworkThumb: saveArtworkThumb,
        setSaveArtworkThumb: setSaveArtworkThumb,
        selectedAttributes: selectedAttributes,
        handleAttributesChange: handleAttributesChange,
        artworkProps: artworkProps,
        setArtworkProps: setArtworkProps,
        quantity: quantity,
        setQuantity: setQuantity,
        selectedArtwork: selectedArtwork,
        setSelectedArtwork: setSelectedArtwork,
        handleSelectArtwork: handleSelectArtwork,
        selectedStandard: selectedStandard,
        handleSelectStandard: handleSelectStandard,
        selectedSpecialty: selectedSpecialty,
        handleSelectSpecialty: handleSelectSpecialty,
        refHandleExport: refHandleExport,
        artworkPanel: artworkPanel,
        setArtworkPanel: setArtworkPanel,
        hasResolutionWarning: hasResolutionWarning,
        setHasResolutionWarning: setHasResolutionWarning,
        imageEditorCtx: imageEditorCtx,
        setImageEditorCtx: setImageEditorCtx,
        currency: (_c = tenant === null || tenant === void 0 ? void 0 : tenant.currency) !== null && _c !== void 0 ? _c : "AUD",
        coaFields: coaFields,
        setCoaFields: setCoaFields
    };
    console.log("context", context);
    var localeToUnderScore = tenant === null || tenant === void 0 ? void 0 : tenant.locale.replace("_", "-");
    return (React.createElement(react_intl_1.IntlProvider, { locale: localeToUnderScore, key: localeToUnderScore },
        React.createElement(Context_1.ProductContext.Provider, { value: context },
            React.createElement("div", { className: "ic-product-widget" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("div", { className: "ic-product-widget-detail py-3 py-md-5" },
                            React.createElement(Detail_1.Detail, null))),
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement(Selector_1.Selector, null)))))));
};
exports.default = Product;
