"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageEditor = void 0;
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var SideMenu_1 = require("./SideMenu");
var ImageView_1 = require("./ImageView");
exports.ImageEditor = React.forwardRef(function (props, outsideRef) {
    var artwork = props.artwork, maskUrl = props.maskUrl, matboardUrl = props.matboardUrl, productDims = props.productDims, stretchDims = props.stretchDims, rotation = props.rotation, artworkPanel = props.artworkPanel, onResolutionWarning = props.onResolutionWarning, canvasTexts = props.canvasTexts, outerArtworkProps = props.outerArtworkProps, signature = props.signature, paddings = props.paddings, isCanvass = props.isCanvass, isMaskOnFrame = props.isMaskOnFrame;
    return (React.createElement(Context_1.ContextProvider, null,
        React.createElement("div", { className: "image-editor" },
            React.createElement("div", { className: "d-flex flex-row align-items-stretch" },
                React.createElement("div", { style: { maxWidth: 130 }, className: "bg-lgray" },
                    React.createElement(SideMenu_1.SideMenu, null)),
                React.createElement("div", { className: "col bg-lgray p-3" },
                    React.createElement(ImageView_1.ImageView, { maskUrl: maskUrl, matboardUrl: matboardUrl, artwork: artwork, productDims: productDims, stretchDims: stretchDims, 
                        // refHandleExport={outsideRef}
                        outerArtworkProps: outerArtworkProps, rotation: rotation, ref: outsideRef, paddings: {
                            x: (paddings === null || paddings === void 0 ? void 0 : paddings.x) || 0,
                            y: (paddings === null || paddings === void 0 ? void 0 : paddings.y) || 0
                        }, artworkPanel: artworkPanel, onResolutionWarning: onResolutionWarning, canvasTexts: canvasTexts, signature: signature, isCanvass: isCanvass, isMaskOnFrame: isMaskOnFrame }))))));
});
