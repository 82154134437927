"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_intl_1 = require("react-intl");
var Tabs_1 = require("./Tabs");
var Table_1 = require("./Table");
var Paginator_1 = require("../../common/Paginator");
var common_1 = require("../../common");
var helpers_1 = require("../../../helpers");
var remote_1 = require("../../../remote");
var initilFilter = {
    status: undefined,
    $skip: 0
};
function default_1() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var tenant = window.__initialState.tenant;
    var _k = react_1.useState(true), isLoading = _k[0], setIsLoading = _k[1];
    var _l = react_1.useState(null), data = _l[0], setData = _l[1];
    var _m = react_1.useState([]), orderStats = _m[0], setOrderStats = _m[1];
    var _o = react_1.useState(initilFilter), filter = _o[0], setFilter = _o[1];
    var _p = react_1.useState("" || undefined), activeTab = _p[0], setActiveTab = _p[1];
    var _q = react_1.useState([]), categories = _q[0], setCategories = _q[1];
    var _r = react_1.useState({
        new: false
    }), isOpenModal = _r[0], setIsOpenModal = _r[1];
    var debouncedFilter = helpers_1.useDebounce(filter, 250);
    var tabOptions = [
        {
            value: undefined,
            label: "All",
            count: orderStats === null || orderStats === void 0 ? void 0 : orderStats.reduce(function (acc, cur) { return acc + (cur === null || cur === void 0 ? void 0 : cur.count); }, 0)
        },
        {
            value: "NEW",
            key: "new",
            label: "New",
            count: (_a = orderStats === null || orderStats === void 0 ? void 0 : orderStats.find(function (s) { return s.status === "new"; })) === null || _a === void 0 ? void 0 : _a.count
        },
        {
            value: "IN_PRODUCTION",
            key: "in-production",
            label: "In Production",
            count: (_c = (_b = orderStats === null || orderStats === void 0 ? void 0 : orderStats.find(function (s) { return s.status === "in-production"; })) === null || _b === void 0 ? void 0 : _b.count) !== null && _c !== void 0 ? _c : 0
        },
        {
            value: "SHIPPED",
            key: "shipped",
            label: "Shipped",
            count: (_e = (_d = orderStats === null || orderStats === void 0 ? void 0 : orderStats.find(function (s) { return s.status === "shipped"; })) === null || _d === void 0 ? void 0 : _d.count) !== null && _e !== void 0 ? _e : 0
        },
        // { value: 'NEEDS_SIGNATURE', label: 'Needs Signature', count: 0 ?? 0 },
        // { value: 'ISSUES', label: 'Issues', count: 0 },
        {
            value: "CANCELLED",
            key: "void",
            label: "Cancelled",
            count: (_g = (_f = orderStats === null || orderStats === void 0 ? void 0 : orderStats.find(function (s) { return s.status === "void"; })) === null || _f === void 0 ? void 0 : _f.count) !== null && _g !== void 0 ? _g : 0
        }
    ];
    react_1.useEffect(function () {
        var loadOrderHistory = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_1;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        setIsLoading(true);
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, remote_1.getOrderHistory(__assign(__assign({}, debouncedFilter), { status: debouncedFilter.status
                                    ? (_a = tabOptions.find(function (t) { return t.value === debouncedFilter.status; })) === null || _a === void 0 ? void 0 : _a.key : debouncedFilter.status }))];
                    case 2:
                        res = _f.sent();
                        setData({
                            paginationInfo: {
                                total: (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.total,
                                limit: (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.limit,
                                skip: (_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.skip
                            },
                            items: (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _f.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        loadOrderHistory();
    }, [debouncedFilter]);
    react_1.useEffect(function () {
        var loadCategory = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, remote_1.getCategories()];
                    case 1:
                        res = _b.sent();
                        setCategories((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data);
                        return [2 /*return*/];
                }
            });
        }); };
        var loadOrderStats = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, remote_1.getOrderStats(debouncedFilter)];
                    case 1:
                        res = _a.sent();
                        setOrderStats(res === null || res === void 0 ? void 0 : res.data.stats);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log("err", e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadOrderStats();
        loadCategory();
    }, []);
    var tableNode = react_1.default.createElement(Table_1.Table, { items: data === null || data === void 0 ? void 0 : data.items });
    if (isLoading) {
        tableNode = (react_1.default.createElement("div", { className: "d-flex justify-content-center" },
            react_1.default.createElement("div", { className: "spinner-border", role: "status" },
                react_1.default.createElement("span", { className: "sr-only" }, "Loading..."))));
    }
    if (!isLoading && !((_h = data === null || data === void 0 ? void 0 : data.items) === null || _h === void 0 ? void 0 : _h.length)) {
        tableNode = (react_1.default.createElement("div", { className: "d-flex justify-content-center" },
            react_1.default.createElement("i", { className: "far fa-file" })));
    }
    var handlePageUpdate = function (skip) {
        return setFilter(__assign(__assign({}, filter), { $skip: skip }));
    };
    var localeToUnderScore = (_j = tenant === null || tenant === void 0 ? void 0 : tenant.locale) === null || _j === void 0 ? void 0 : _j.replace("_", "-");
    return (react_1.default.createElement(react_intl_1.IntlProvider, { locale: localeToUnderScore, key: localeToUnderScore },
        react_1.default.createElement("div", { className: "d-flex justify-content-between mb-3" },
            react_1.default.createElement("h2", null, "Order"),
            react_1.default.createElement("div", null,
                react_1.default.createElement("button", { className: "btn btn-pink", onClick: function () {
                        return setIsOpenModal(__assign(__assign({}, isOpenModal), { new: true }));
                    } },
                    "Create Order ",
                    react_1.default.createElement("i", { className: "fas fa-plus" })))),
        react_1.default.createElement("div", { className: "mb-5" },
            react_1.default.createElement(Tabs_1.Tabs, { tabs: tabOptions, activeTab: activeTab, onActive: function (v) {
                    setActiveTab(v);
                    setFilter(__assign(__assign({}, filter), { status: v }));
                } })),
        tableNode,
        react_1.default.createElement(Paginator_1.Paginator, { data: data === null || data === void 0 ? void 0 : data.paginationInfo, onPageUpdate: handlePageUpdate }),
        isOpenModal.new && (react_1.default.createElement(common_1.AddProduct, { categories: categories, onClose: function () {
                return setIsOpenModal(__assign(__assign({}, isOpenModal), { new: false }));
            } }))));
}
exports.default = default_1;
