"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var Context_1 = require("./Context");
var remote_1 = require("../../../remote");
var common_1 = require("../../common");
function DeleteCollections() {
    var _this = this;
    var _a, _b;
    var ctx = react_1.useContext(Context_1.ArtworkContext);
    var setIsReload = ctx.setIsReload, isModalOpen = ctx.isModalOpen, collections = ctx.collections, handleToggleModal = ctx.handleToggleModal;
    var _c = react_1.useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = react_1.useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var _e = react_1.useState({}), selected = _e[0], setSelected = _e[1];
    var filteredCollections = collections === null || collections === void 0 ? void 0 : collections.filter(function (c) { return c.value; });
    var handleToggleAll = function (e) {
        var checked = e.target.checked;
        if (checked) {
            var all = filteredCollections === null || filteredCollections === void 0 ? void 0 : filteredCollections.reduce(function (acc, cur) {
                acc[cur.value] = true;
                return acc;
            }, {});
            setSelected(all);
            return null;
        }
        setSelected({});
    };
    var handleCheckboxChange = function (e) {
        var _a;
        var _b = e.target, checked = _b.checked, value = _b.value;
        if (checked) {
            setSelected(__assign(__assign({}, selected), (_a = {}, _a[value] = true, _a)));
            return null;
        }
        var updated = __assign({}, selected);
        delete updated[value];
        setSelected(updated);
    };
    var handleDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var resSelect, e_1, errMsg;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setErrorMessage("");
                    setIsSubmitting(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, ((_a = Object.keys(selected)) === null || _a === void 0 ? void 0 : _a.map(function (s) {
                            return remote_1.deleteArtworkLibraryCollection(s);
                        }))];
                case 2:
                    resSelect = _b.sent();
                    return [4 /*yield*/, Promise.all(resSelect)];
                case 3:
                    _b.sent();
                    setIsReload(function (prev) { return (__assign(__assign({}, prev), { collections: true })); });
                    setSelected({});
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _b.sent();
                    errMsg = (e_1 === null || e_1 === void 0 ? void 0 : e_1.code) < 500
                        ? e_1 === null || e_1 === void 0 ? void 0 : e_1.message : "Something went wrong. Please try again later.";
                    setErrorMessage(errMsg);
                    return [3 /*break*/, 5];
                case 5:
                    setIsSubmitting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var areAllSelected = ((_a = Object.keys(selected)) === null || _a === void 0 ? void 0 : _a.length) === (filteredCollections === null || filteredCollections === void 0 ? void 0 : filteredCollections.length);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(common_1.ModalWrapper, { isOpen: true, onClose: function () {
                setErrorMessage("");
                handleToggleModal("deleteCollections");
            }, style: { maxWidth: "450px" } },
            react_1.default.createElement("h3", { className: "text-center" }, "All Collections"),
            react_1.default.createElement("div", { className: "rounded-corner p-3 p-md-4 bg-white mb-3" },
                errorMessage && (react_1.default.createElement("div", { className: "alert alert-danger mt-3" }, errorMessage)),
                react_1.default.createElement("div", { className: "mt-3 " },
                    react_1.default.createElement("div", { className: "form-group form-check" },
                        react_1.default.createElement("label", { className: "form-check-label font-weight-bold" },
                            " ",
                            react_1.default.createElement("input", { type: "checkbox", className: "form-check-input", checked: areAllSelected, onChange: handleToggleAll }),
                            " ",
                            "Select All")), filteredCollections === null || filteredCollections === void 0 ? void 0 :
                    filteredCollections.map(function (c, idx) { return (react_1.default.createElement("div", { key: idx, className: "form-group form-check" },
                        react_1.default.createElement("label", { className: "form-check-label" },
                            react_1.default.createElement("input", { type: "checkbox", className: "form-check-input", checked: selected[c === null || c === void 0 ? void 0 : c.value], value: c === null || c === void 0 ? void 0 : c.value, onChange: handleCheckboxChange }),
                            " ", c === null || c === void 0 ? void 0 :
                            c.label))); }),
                    react_1.default.createElement("div", { className: "text-center " },
                        react_1.default.createElement(common_1.Button, { disabled: ((_b = Object.keys(selected)) === null || _b === void 0 ? void 0 : _b.length) === 0 || isSubmitting, loading: isSubmitting, content: "Delete", className: "mt-3", onClick: handleDelete })))))));
}
exports.default = DeleteCollections;
