"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomText = void 0;
var React = __importStar(require("react"));
var react_konva_1 = require("react-konva");
var react_konva_utils_1 = require("react-konva-utils");
function CustomText(_a) {
    var textProps = _a.textProps, onSelect = _a.onSelect, onChangeSignature = _a.onChangeSignature, onDelete = _a.onDelete, isSelected = _a.isSelected, onChange = _a.onChange, onPositionText = _a.onPositionText, _b = _a.isTransformDisabled, isTransformDisabled = _b === void 0 ? false : _b;
    var shapeRef = React.useRef(null);
    var trRef = React.useRef(null);
    var _c = React.useState(false), isEditing = _c[0], setIsEditing = _c[1];
    var _d = React.useState(false), isDeleting = _d[0], setIsDeleting = _d[1];
    var _e = React.useState(textProps.text), text = _e[0], setText = _e[1];
    var _f = React.useState({ x: 0, y: 0 }), textPosition = _f[0], setTextPosition = _f[1];
    React.useEffect(function () {
        var _a, _b;
        if (isSelected) {
            (_a = trRef === null || trRef === void 0 ? void 0 : trRef.current) === null || _a === void 0 ? void 0 : _a.nodes([shapeRef === null || shapeRef === void 0 ? void 0 : shapeRef.current]);
            (_b = trRef === null || trRef === void 0 ? void 0 : trRef.current) === null || _b === void 0 ? void 0 : _b.getLayer().batchDraw();
        }
    }, [isSelected]);
    var toggle = function () {
        onSelect();
        setIsDeleting(false);
        setIsEditing(false);
    };
    console.log("IN CustomText", { isTransformDisabled: isTransformDisabled });
    return (React.createElement(React.Fragment, null,
        React.createElement(react_konva_1.Text
        //  text={t.text}
        //  fill={t.fill}
        //  fontSize={t.fontSize}
        //  fontFamily={t.fontFamily}
        , __assign({ 
            //  text={t.text}
            //  fill={t.fill}
            //  fontSize={t.fontSize}
            //  fontFamily={t.fontFamily}
            onDblClick: function (e) {
                setIsEditing(true);
            }, on: true, draggable: !isTransformDisabled, onContextMenu: function (e) {
                e.evt.preventDefault();
                setIsDeleting(true);
            }, onDragStart: toggle, onDragEnd: function (e) {
                setTextPosition({
                    x: e.target.x(),
                    y: e.target.y()
                });
                onPositionText === null || onPositionText === void 0 ? void 0 : onPositionText(textProps.index, e.target.x(), e.target.y());
                onChangeSignature === null || onChangeSignature === void 0 ? void 0 : onChangeSignature(__assign(__assign({}, textProps), { x: e.target.x(), y: e.target.y() }));
            }, onClick: toggle, onTap: toggle, ref: shapeRef }, textProps)),
        isSelected && !isTransformDisabled && (React.createElement(react_konva_1.Transformer, { ref: trRef, resizeEnabled: false, boundBoxFunc: function (oldBox, newBox) {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                }
                return newBox;
            } })),
        isSelected && isDeleting && !isEditing && (React.createElement(react_konva_utils_1.Html, null,
            React.createElement("button", { style: {
                    position: "absolute",
                    top: textPosition.y,
                    left: textPosition.x,
                    outline: "none",
                    border: "1px solid #000",
                    backgroundColor: "#fff",
                    fontSize: "12px",
                    cursor: "pointer"
                }, onClick: onDelete }, "Delete"))),
        isSelected && isEditing && (React.createElement(react_konva_utils_1.Html, null,
            React.createElement("textarea", { value: text, autoFocus: true, style: {
                    position: "absolute",
                    top: textPosition.y || textProps.y,
                    left: textPosition.x || textProps.x,
                    resize: "none",
                    outline: "none",
                    border: "none"
                }, onChange: function (e) { return setText(e.target.value); }, onKeyDown: function (e) {
                    if (e.key === "Enter" && !e.shiftKey) {
                        setIsEditing(false);
                        onChange === null || onChange === void 0 ? void 0 : onChange(text);
                    }
                    if (e.key === "Escape") {
                        setIsEditing(false);
                    }
                    // if (e.keyCode === 13 && !e.shiftKey) {
                    //   textNode.text(textarea.value);
                    //   removeTextarea();
                    // }
                    // // on esc do not set value back to node
                    // if (e.keyCode === 27) {
                    //   removeTextarea();
                    // }
                } })))));
}
exports.CustomText = CustomText;
