"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryContainer = void 0;
var react_1 = __importStar(require("react"));
var react_intl_1 = require("react-intl");
var Context_1 = require("./Context");
var remote_1 = require("../../remote");
function CategoryContainer(props) {
    var _this = this;
    var _a, _b, _c;
    var page = window.__initialState.page;
    // console.log('page', page);
    var mounted = react_1.useRef(true);
    var _d = react_1.useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = react_1.useState(null), data = _e[0], setData = _e[1];
    var _f = react_1.useState([]), attributes = _f[0], setAttributes = _f[1];
    var _g = react_1.useState(""), selectedAttribute = _g[0], setSelectedAttribute = _g[1];
    var loadCategoryAttr = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    setIsLoading(true);
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, remote_1.getCatAttributes((_a = page === null || page === void 0 ? void 0 : page.category) === null || _a === void 0 ? void 0 : _a._id)];
                case 2:
                    res = _f.sent();
                    setData(res === null || res === void 0 ? void 0 : res.data);
                    // const group = res?.data?.attributes?.reduce((acc, cur) => {
                    //   if (!acc[cur.typeId]) {
                    //     acc[cur.typeId] = [cur]
                    //   } else {
                    //     acc[cur.typeId].push(cur)
                    //   }
                    //   return acc
                    // }, {})
                    setAttributes((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.attributes);
                    setSelectedAttribute((_e = (_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e._id);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _f.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    react_1.useEffect(function () {
        loadCategoryAttr();
        return function () {
            mounted.current = false;
        };
    }, []);
    var ctx = {
        data: data,
        isLoading: isLoading,
        attributes: attributes,
        selectedAttribute: selectedAttribute,
        setSelectedAttribute: setSelectedAttribute
    };
    var localeToUnderScore = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.initialState) === null || _a === void 0 ? void 0 : _a.tenant) === null || _b === void 0 ? void 0 : _b.locale) === null || _c === void 0 ? void 0 : _c.replace("_", "-");
    return (react_1.default.createElement(react_intl_1.IntlProvider, { locale: localeToUnderScore, key: localeToUnderScore },
        react_1.default.createElement(Context_1.CategoryContext.Provider, { value: ctx }, props.children)));
}
exports.CategoryContainer = CategoryContainer;
