"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalWrapper = void 0;
var React = __importStar(require("react"));
var react_overlays_1 = require("react-overlays");
var backdropStyle = {
    position: "fixed",
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5
};
exports.ModalWrapper = function (props) {
    var _a = props.className, className = _a === void 0 ? "" : _a, isOpen = props.isOpen, onClose = props.onClose, label = props.label, _b = props.style, style = _b === void 0 ? {} : _b;
    var renderBackdrop = function (props) {
        return React.createElement("div", __assign({}, props, { style: backdropStyle }));
    };
    return (React.createElement(react_overlays_1.Modal, { className: "ic-modal " + className, onHide: onClose, "aria-labelledby": "modal-label", show: isOpen, renderBackdrop: renderBackdrop, style: style },
        React.createElement("div", { className: "ic-modal-inner" },
            React.createElement("div", { className: "text-right" },
                React.createElement("button", { className: "btn btn-modal-close", onClick: onClose },
                    React.createElement("i", { className: "fas fa-2x fa-times-circle" }))),
            props.children)));
};
